// Libraries
import styled from 'styled-components'

// Components
import { Body, Checkbox, ToggleButton } from 'heeds-ds'

type scheduleProps = {
  active?: boolean
  width?: string
  conflict?: boolean
}

export const Container = styled.div<scheduleProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.subdued};
  border-radius: 3px;
  gap: 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 3.2rem 1.6rem;
  width: ${({ width }) => width || '100%'};
  margin-left: ${({ conflict }) => (conflict ? '-40%' : '0px')};
  ::before {
    content: '';
    background-color: ${({ active, theme }) =>
      active ? theme.colors.icon.default : theme.colors.icon.disabled};
    border-radius: 3px 0 0 3px;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 4px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-left: 0px;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: min-content max-content;
    padding: 1.2rem 2.4rem;
    ::before {
      visibility: ${({ active }) => (active ? 'hidden' : 'visible')};
    }
  }
`
export const Content = styled.div<scheduleProps>`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  width: 100%;
`

export const MediumCheckbox = styled(Checkbox).attrs(() => ({
  size: 'medium',
}))``

export const WarperSimpleToggle = styled.div`
  grid-area: 1 / 2 / 2 / 9;
`

export const SimpleToggle = styled(ToggleButton).attrs(() => ({}))``

export const ConflictWarper = styled.div`
  display: flex;
  margin: 0 10px 0 16px;
`

export const IconWarper = styled.div`
  background-color: ${({ theme }) => theme.colors.icon.critical};
  padding: 10px;
  border-radius: 10px;
`

export const ConflictText = styled(Body).attrs(() => ({
  type: 'copy4',
}))`
  color: ${({ theme }) => theme.colors.text.critical};
  margin-left: 16px;
`

export const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.icon.critical};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 8px 0px 16px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`
