// Models
import { Props as BaseProps, TPublicProps } from '../BaseBlock'

// React
import { FC } from 'react'

// Components
import BaseBlock from '../BaseBlock'
import { Aligner, InputText } from 'heeds-ds'

type Props = Pick<BaseProps, TPublicProps>

const MeasurementBlock: FC<Props> = ({ name, ...props }) => {
  return (
    <BaseBlock {...props} mandatoryField name={name} type="measures">
      <Aligner gap="0.8rem" align="flex-end">
        <InputText
          displayError={false}
          label="Digite sua pergunta"
          name={`${name}.question`}
          placeholder="Sua pergunta para o aluno vem aqui"
          scale="small"
        />

        <InputText
          displayError={false}
          name={`${name}.allowed_values.0`}
          placeholder="cm"
          scale="small"
          className="w-40"
        />
      </Aligner>
    </BaseBlock>
  )
}

export default MeasurementBlock
