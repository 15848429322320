// Models
import { ChangeHandler, FieldError, RefCallBack } from 'react-hook-form'

// React
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  InputHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import { Body } from '../../UI/Typography'
import {
  IconButton,
  InputContainer,
  InputWrapperInner,
  Option,
  OptionsContainer,
  SimpleInput,
} from './styled'
import Icon from '../../UI/Icons'
import InputWrapper from '../InputWrapper'

type EventType = FormEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError
  event?: 'critical' | 'success' | 'default' | 'focused'
  label?: string
  mandatory?: boolean
  className?: string
  maskFunction?: (value: string) => string
  name: string
  onBlur: ChangeHandler
  onChange: ChangeHandler
  options: string[]
  optionsCallBack?: (option: string) => void
  refName: RefCallBack
  scale?: 'small' | 'medium' | 'large'
  showIcon?: boolean
  type?: string
  value?: string
  width?: string
}

enum EIconSizes {
  small = 20,
  medium = 21.6,
  large = 25,
}

const InputPicker: FC<TProps> = (props: TProps) => {
  const {
    disabled,
    error,
    event = 'default',
    label,
    maskFunction,
    name,
    onChange,
    onBlur,
    placeholder,
    refName,
    scale = 'medium',
    showIcon,
    type,
    value = '',
    className,
    mandatory,
    options,
    optionsCallBack,
    width,
  } = props
  const [eventAlert, setEventAlert] = useState(event)
  const [focused, setFocused] = useState(false)
  const [onceError, setOnceError] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [stateValue, setStateValue] = useState(value)

  useEffect(() => {
    setStateValue(value)
  }, [value])

  const theme = useContext(ThemeContext)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const textColor = {
    critical: theme.colors.text.critical,
    default: theme.colors.border.input,
    focused: theme.colors.text.default,
    success: theme.colors.text.success,
  }

  const handleOnBlur = (event: EventType) => {
    onBlur(event)
    setFocused(false)
    setEventAlert(stateValue ? 'focused' : 'default')
    setTimeout(() => {
      setShowOptions(false)
    }, 300)
  }

  const handleOnChange = (event: EventType) => {
    if (maskFunction) {
      event.currentTarget.value = maskFunction(event.currentTarget.value)
    }
    setStateValue(event.currentTarget.value)
    onChange(event)
  }

  const handleOptions = (option: string) => {
    setStateValue(option)
    setShowOptions(false)
  }

  useEffect(() => {
    optionsCallBack && optionsCallBack(stateValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateValue])

  const handleOnFocus = () => {
    setFocused(true)
    setEventAlert('focused')
    setShowOptions(true)
  }

  const toggleShowList = (event: React.MouseEvent) => {
    event.stopPropagation()
    setShowOptions((prev) => !prev)
  }

  const inputProps = {
    placeholder: placeholder,
    disabled: disabled,
    color: textColor[eventAlert],
    onBlur: handleOnBlur,
    onChange: handleOnChange,
    onFocus: handleOnFocus,
    value: stateValue,
    ref: refName,
    name: name,
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (error) {
      setOnceError(true)
      setEventAlert('critical')
    } else {
      setEventAlert(onceError ? 'success' : focused ? 'focused' : 'default')
    }
  }, [error, focused, onceError])

  return (
    <InputWrapper
      className={className}
      color={eventAlert}
      disabled={disabled}
      error={error?.message}
      label={label}
      mandatory={mandatory}
      size={scale}
      type={type}
    >
      <InputWrapperInner ref={wrapperRef}>
        <InputContainer borderColor={textColor[eventAlert]}>
          <SimpleInput
            {...inputProps}
            scale={scale}
            width={width}
            data-testid="input-picker"
            autoComplete="off"
          />
        </InputContainer>
        {showIcon && (
          <IconButton onClick={toggleShowList} scale={scale}>
            <Icon
              color={
                showOptions
                  ? theme.colors.icon.default
                  : theme.colors.icon.pressed
              }
              iconName={showOptions ? 'expandLess' : 'expandMore'}
              size={EIconSizes[scale]}
            />
          </IconButton>
        )}
        {showOptions && (
          <OptionsContainer>
            {options
              .filter((name) =>
                name.toLowerCase().includes(stateValue.toLowerCase()),
              )
              .map((item, index) => {
                return (
                  <Option key={index} onClick={() => handleOptions(item)}>
                    <Body type="copy4">{item}</Body>
                  </Option>
                )
              })}
          </OptionsContainer>
        )}
      </InputWrapperInner>
    </InputWrapper>
  )
}

export default InputPicker
