// Libs
import { cva } from 'class-variance-authority'

export const inputStyles = cva(
  [
    'disabled:cursor-not-allowed disabled:bg-surface-disabled disabled:text-text-subdued',
    'w-full cursor-text border-none bg-transparent text-text caret-[#202223]',
    'placeholder:text-text-subdued disabled:placeholder:text-text-disabled',
    'pl-4 outline-none transition-all duration-300 ease-in-out',
    'read-only:pointer-events-none read-only:cursor-auto',
  ],
  {
    variants: {
      scale: {
        small:
          'py-3 text-copy4 [aria-type="password"]:py-3 [aria-type="password"]:pr-[50px]',
        medium:
          'py-[15px] text-copy3 [aria-type="password"]:py-[15px] [aria-type="password"]:pr-[50px]',
        large:
          'py-[18px] text-copy2 [aria-type="password"]:py-[18px] [aria-type="password"]:pr-[50px]',
      },
    },
    defaultVariants: {
      scale: 'small',
    },
  },
)
