// Models
import { IAuthState } from 'storage/auth/models'
import { IMultiSelectOption } from 'heeds-ds/src/models'
import { IExerciseData, IExerciseState } from 'storage/exercise/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'

// Misc
import { cn } from 'utils/helpers/classess'
import { ExerciseSchema } from 'schemas'
import {
  DIFFICULTY_TYPE_FIELD_OPTIONS,
  EXERCISE_TYPE_FIELD_OPTIONS,
  MECHANIC_FIELD_OPTIONS,
  FORCE_TYPE_FIELD_OPTIONS,
  MUSCLE_LIST_TYPE_FIELD_OPTIONS,
  MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS,
} from 'utils/constants'
import { useMediaQuery } from 'hooks'
import {
  triggerCreateExercise,
  triggerLoadEquipment,
  triggerLoadExercise,
  triggerPartialUpdateExercise,
} from 'storage/exercise/duck'
import {
  IExerciseDisplay,
  prepareExerciseToDisplay,
  prepareExerciseToPayload,
} from 'filters/exercises'

// Components
import {
  Button,
  MultiSelect,
  Icon,
  InputText,
  Loading,
  Select,
  TextArea,
  Tooltip,
} from 'heeds-ds'
import { InputFile } from 'components'

type TLabel = {
  title: string
  mandatory?: boolean
  tooltip?: string
}

const LibraryExerciseCreate: FC = () => {
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { equipments, loading } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )

  const [preview, setPreview] = useState<string>('')

  const methods = useForm<IExerciseDisplay>({
    resolver: yupResolver(ExerciseSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = methods

  const onSubmit = (form: IExerciseDisplay) => {
    const payload = prepareExerciseToPayload(form, userData?.profileId || 0, id)

    if (id) {
      dispatch(triggerPartialUpdateExercise(payload))
      navigate(-1)
      return
    }
    dispatch(triggerCreateExercise(payload))
    navigate(-1)
  }

  const handleSelectedSpecificList = (
    selectedMuscle?: IMultiSelectOption[],
  ) => {
    if (selectedMuscle && selectedMuscle.length > 0) {
      const selectedGroupIds = selectedMuscle
        .map((select: IMultiSelectOption) =>
          select?.id ? Math.floor(select.id / 1000) * 1000 : null,
        )
        .filter((id) => id !== null)

      return MUSCLE_LIST_TYPE_FIELD_OPTIONS.filter((muscle) =>
        selectedGroupIds.includes(Math.floor(muscle.id / 1000) * 1000),
      )
    } else {
      return MUSCLE_LIST_TYPE_FIELD_OPTIONS
    }
  }

  const renderLabel = (label: TLabel) => {
    const { title, mandatory = false, tooltip } = label
    return (
      <div className="flex w-full gap-2">
        <div className="flex items-center">
          <label className="font-roboto text-copy4 font-semibold text-text">
            {title}
          </label>
          <p
            className={cn(
              'ml-1 font-roboto text-copy4 font-semibold ',
              mandatory ? 'text-text-critical' : 'text-text-subdued',
            )}
          >
            {mandatory ? '(obrigatório)' : '(opcional)'}
          </p>
        </div>
        {tooltip && (
          <Tooltip content={<p>{tooltip}</p>} delay={0}>
            <div className="ml-auto">
              <Icon iconName="info" size={16} />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  const equipmentToList = useMemo(() => {
    return equipments?.map((muscle) => {
      return {
        label: muscle.name,
        value: muscle.name,
      }
    })
  }, [equipments])

  const selectedAgonistGroup = watch('agonists_muscle')
  const selectedSynergistGroup = watch('synergists_muscle')

  const inputOptions = [
    {
      title: 'Nome do exercício',
      subtitle:
        'Esse nome ficará registrado na sua biblioteca e para o seu aluno.',
      input: (
        <div className="flex flex-col gap-2">
          {renderLabel({ title: 'Nome', mandatory: true })}
          <InputText name={'name'} scale="small" />
        </div>
      ),
    },
    {
      title: 'Arquivo do exercicio',
      subtitle:
        'Adicione uma foto ou gif apresentando como esse exercício deve ser executado.',
      input: (
        <div className="flex w-full flex-col gap-1">
          <p
            className={cn(
              'ml-1 font-roboto text-copy4 font-semibold text-text-critical',
            )}
          >
            (obrigatório)
          </p>
          <div className="flex w-full gap-4">
            {preview ? (
              <img
                src={preview}
                className="h-[80px] w-[82px] rounded-lg bg-icon object-fill opacity-50"
              />
            ) : (
              <div
                className={cn(
                  'flex h-[80px] w-[82px] items-center justify-center rounded-lg bg-icon',
                  'object-fill p-5 opacity-50',
                )}
              >
                <Icon
                  iconName="playCircle"
                  size={40}
                  color={theme.colors.surface.default}
                />
              </div>
            )}
            <InputFile
              setFile={(file) =>
                setValue('file', file as File, { shouldValidate: true })
              }
              preview={(img) => setPreview(img as string)}
              accept="image/*,image/gif,video/mp4"
              singleFile
              size={15}
              dragText={
                <div className="flex h-[8opx] w-full flex-wrap items-center justify-center md:px-[60px]">
                  <p className="text-copy4 font-semibold text-interactive md:whitespace-nowrap">
                    Clique aqui para adicionar
                  </p>
                  {isDesktop && (
                    <p className="pl-1 text-copy4 font-normal md:whitespace-nowrap">
                      ou arraste para cá.
                    </p>
                  )}
                  <p className="text-center text-copy4 font-normal md:whitespace-nowrap ">
                    PNG, JPG, JPEG, GIF, MP4
                  </p>
                </div>
              }
              error={id === '' ? errors.file?.message : ''}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Instruções',
      subtitle:
        'Use esse espaço para explicar ao seu aluno a melhor forma de executar o exercício. Ficará apresentado para ele como um comentário.',
      input: (
        <div className="flex flex-col gap-2">
          {renderLabel({ title: 'Instruções' })}
          <TextArea name="instructions" height={110} scale="small" />
        </div>
      ),
    },
    {
      title: 'Grupo muscular',
      subtitle:
        'Selecione qual grupo muscular esse exercício faz parte e logo em seguida qual músculo ele trabalha.',
      input: (
        <div>
          <div>
            <div className="mb-4 flex flex-col gap-4 md:flex-row">
              <MultiSelect
                items={MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS}
                label={renderLabel({
                  title: 'Grupo muscular agonista',
                  tooltip:
                    'Músculos principais responsáveis pela contração no exercício',
                })}
                className="w-full md:w-[277px]"
                placeholder="Selecione"
                name="agonists_muscle"
              />
              {(selectedAgonistGroup === undefined ||
                selectedAgonistGroup?.length !== 0) && (
                <MultiSelect
                  items={handleSelectedSpecificList(selectedAgonistGroup)}
                  label={renderLabel({ title: 'Músculo' })}
                  className="w-full md:w-[277px]"
                  placeholder="Selecione"
                  name="agonists_muscle_specific"
                />
              )}
            </div>
            <div className="mb-4 flex flex-col gap-4 md:flex-row">
              <MultiSelect
                items={MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS}
                label={renderLabel({
                  title: 'Grupo muscular sinergista',
                  tooltip:
                    'Músculos que ajudam a sustentar e estabilizar o movimento, auxiliando os agonistas.',
                })}
                className="w-full md:w-[277px]"
                placeholder="Selecione"
                name="synergists_muscle"
              />
              {(selectedSynergistGroup === undefined ||
                selectedSynergistGroup?.length !== 0) && (
                <MultiSelect
                  items={handleSelectedSpecificList(selectedSynergistGroup)}
                  label={renderLabel({ title: 'Músculo' })}
                  className="w-full md:w-[277px]"
                  name="synergists_muscle_specific"
                  placeholder="Selecione"
                />
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Equipamento',
      subtitle:
        'Selecione qual grupo de equipamentos e também qual deles o exercício faz parte.',
      input: (
        <div>
          <div className="mb-4 flex flex-col gap-4 md:flex-row">
            <label className="w-full">
              {renderLabel({ title: 'Equipamento' })}
              <Select
                name={'equipment'}
                options={equipmentToList || []}
                scale="small"
                className="mt-2"
              />
            </label>
          </div>
        </div>
      ),
    },
    {
      title: 'Informações adicionais',
      subtitle:
        'Você pode adicionar algumas informações que facilitará na busca dos seus exercícios.',
      input: (
        <div>
          <div className="mb-4 flex flex-col gap-4 md:flex-row">
            <label className="w-full md:w-[277px]">
              {renderLabel({ title: 'Tipo de força' })}
              <Select
                name={'force_type'}
                options={FORCE_TYPE_FIELD_OPTIONS || []}
                scale="small"
                placeholder="Selecione"
                className="mt-2"
              />
            </label>
            <label className="w-full md:w-[277px]">
              {renderLabel({ title: 'Dificuldade' })}
              <Select
                name={'difficulty'}
                options={DIFFICULTY_TYPE_FIELD_OPTIONS || []}
                scale="small"
                placeholder="Selecione"
                className="mt-2"
              />
            </label>
          </div>
          <div className="mb-4 flex flex-col gap-4 md:flex-row">
            <label className="w-full md:w-[277px]">
              {renderLabel({ title: 'Tipo de exercício' })}
              <Select
                name={'exercise_type'}
                options={EXERCISE_TYPE_FIELD_OPTIONS || []}
                scale="small"
                placeholder="Selecione"
                className="mt-2"
              />
            </label>
            <label className="w-full md:w-[277px]">
              {renderLabel({ title: 'Mecânica' })}
              <Select
                name={'mechanic'}
                options={MECHANIC_FIELD_OPTIONS || []}
                scale="small"
                placeholder="Selecione"
                className="mt-2"
              />
            </label>
          </div>
        </div>
      ),
    },
  ]

  const handleMuscleSelected = useCallback(
    (
      muscleLabel: keyof IExerciseDisplay,
      groupLabel: keyof IExerciseDisplay,
    ) => {
      const group = watch(groupLabel) as IMultiSelectOption[]

      if (group.length === 0) {
        setValue(muscleLabel, '')
        return
      }
      const startValue = watch(muscleLabel) as IMultiSelectOption[]
      const selectedGroup = handleSelectedSpecificList(
        group as IMultiSelectOption[],
      )

      const commonItems =
        startValue &&
        selectedGroup.filter((group) =>
          startValue?.some((muscle) => group.id === muscle.id),
        )

      setValue(muscleLabel, commonItems)
    },
    [setValue, watch],
  )

  useEffect(() => {
    handleMuscleSelected('agonists_muscle_specific', 'agonists_muscle')
  }, [handleMuscleSelected, selectedAgonistGroup])

  useEffect(() => {
    handleMuscleSelected('synergists_muscle_specific', 'synergists_muscle')
  }, [handleMuscleSelected, selectedSynergistGroup])

  useEffect(() => {
    if (equipments === undefined) {
      dispatch(triggerLoadEquipment())
    }
  }, [dispatch, equipments])

  useLayoutEffect(() => {
    if (id) {
      const successCallback = (exercise: IExerciseData) => {
        const data = prepareExerciseToDisplay(exercise)
        reset(data)
        setPreview(exercise?.thumbnail || '')
      }
      dispatch(triggerLoadExercise({ id: Number(id), successCallback }))
    }
  }, [dispatch, id, reset, setValue])

  if (loading) {
    return <Loading active />
  }

  return (
    <div
      className={cn(
        'flex size-full flex-col overflow-hidden',
        'md:pb-6 md:pl-12 md:pr-10  md:pt-8',
      )}
    >
      <div
        className={
          'hidden items-center rounded-lg border border-border-input bg-white py-4 shadow-md md:flex'
        }
      >
        <Button
          variation="borderless"
          onClick={() => navigate(-1)}
          size="xsmall"
        >
          <Icon iconName="arrowBack" />
          Voltar para modelos
        </Button>
        <div className="mx-auto flex flex-col items-center pr-[217px]">
          <p className="mt-1 font-roboto text-copy3 font-semibold text-text">
            Modelo de Exercício
          </p>
          <p className="font-roboto text-copy4 text-text-subdued">Biblioteca</p>
        </div>
      </div>

      <div
        className={cn(
          'flex flex-col items-start overflow-hidden border bg-white  shadow-md',
          'md:mt-[14px] md:rounded-lg md:border-border-input md:py-4',
        )}
      >
        <FormProvider {...methods}>
          <form
            className="flex min-h-[78vh] w-full flex-col justify-between"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex w-full flex-col overflow-auto pb-80">
              {inputOptions.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={cn(
                      'flex w-full flex-col gap-4 px-6 py-4',
                      'md:flex-row md:gap-20',
                    )}
                  >
                    <div className="flex flex-col gap-1 md:max-w-[272px]">
                      <p className="mt-1 font-roboto text-copy4 font-semibold text-text">
                        {option.title}
                      </p>
                      <p className="font-roboto text-copy4 text-text-subdued">
                        {option.subtitle}
                      </p>
                    </div>
                    <div className="w-full md:w-[570px]">{option.input}</div>
                  </div>
                )
              })}
            </div>
            <div className="flex w-full justify-between border-t border-t-border-input px-10 py-4">
              <Button variation="outlined" cancel onClick={() => navigate(-1)}>
                Cancelar
              </Button>
              <Button type="submit">Salvar</Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default LibraryExerciseCreate
