// Libraries
import { InputText } from 'heeds-ds'
import styled from 'styled-components'

export const FormContainer = styled.form``

export const Input = styled(InputText).attrs({
  scale: 'small',
  className: 'mb-[1px]',
})``

export const InputsGrid = styled.div`
  display: grid;
  gap: 1.2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 75%;
  }
`
