// Models
import { useController, useFormContext } from 'react-hook-form'

// React
import React, { useMemo, useState } from 'react'

// Misc
import { cn } from '../../../utils/classes'

// Components
import InputCheckbox from '../@primitives/InputCheckbox'

type Props = {
  className?: string
  disabled?: boolean
  id: string
  inputValue?: string
  isCheckboxGroup?: boolean
  label?: string
  name: string
  size?: 'large' | 'medium'
}

const Checkbox = ({
  className,
  disabled,
  id,
  inputValue,
  isCheckboxGroup = false,
  label,
  name,
  size = 'medium',
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)

  const { control } = useFormContext()
  const {
    field: { onChange, ref, value },
  } = useController({
    name,
    control,
    defaultValue: isCheckboxGroup ? [] : false,
  })
  const isChecked = useMemo(
    () => (Array.isArray(value) ? value.includes(inputValue) : !!value),
    [inputValue, value],
  )

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.checked

    if (isCheckboxGroup) {
      const newCheckedValue = eventValue
        ? [...value, inputValue]
        : value.filter((item: string) => item !== inputValue)

      onChange(newCheckedValue)
    } else if (inputValue) {
      onChange(eventValue ? inputValue : '')
    } else {
      onChange(eventValue)
    }
  }

  return (
    <div className={cn('relative m-0 w-fit', className)}>
      <label
        aria-disabled={disabled}
        data-testid="label"
        htmlFor={id}
        className={cn(
          'm-0 flex cursor-pointer items-center gap-3 text-copy4 font-extrabold text-text-secondary',
          'aria-disabled:cursor-not-allowed aria-disabled:text-text-disabled',
        )}
      >
        <InputCheckbox
          aria-checked={isChecked}
          aria-disabled={disabled}
          checked={isChecked}
          data-testid="input"
          disabled={disabled}
          isFocused={isFocused}
          id={id}
          name={name}
          onChange={handleCheckboxChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          ref={ref}
          role="checkbox"
          scale={size}
          value={inputValue}
        />
        {label}
      </label>
    </div>
  )
}

export default Checkbox
