// Libraries
import { useController, useFormContext } from 'react-hook-form'

// Misc
import { cn } from '../../../utils/classes'

// Components
import InputWrapper from '../InputWrapper'
import Radio from '../@primitives/Radio'

type Option = {
  label: string
  value: string
}

type Props = {
  alignment?: 'row' | 'column'
  checked?: string
  className?: string
  disabled?: boolean
  displayError?: boolean
  label?: string
  mandatory?: boolean
  name: string
  options: Option[]
  scale?: 'small' | 'medium' | 'large'
}

const RadioGroup = ({
  alignment = 'row',
  disabled,
  displayError,
  label,
  mandatory,
  name,
  options,
  scale,
  className,
}: Props) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name })

  return (
    <InputWrapper
      disabled={disabled}
      displayError={displayError}
      label={label}
      mandatory={mandatory}
      size={scale}
      error={error?.message}
      className={className}
    >
      <div
        data-testid="options-list"
        className={cn('mt-3 flex flex-col gap-6', {
          'pointer-events-none': disabled,
          'flex-row gap-10': alignment === 'row',
        })}
      >
        {options.map((option) => (
          <Radio
            checked={option.value === value}
            data-testid={`option-${option.value}`}
            disabled={disabled}
            key={option.value}
            label={option.label}
            onChange={onChange}
            scale={scale !== 'large' ? 'default' : 'large'}
            value={option.value}
          />
        ))}
      </div>
    </InputWrapper>
  )
}

export default RadioGroup
