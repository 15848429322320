// React
import { FC, useRef } from 'react'

// Libraries
import { useController, useFormContext } from 'react-hook-form'

// Components
import { OptionsWrapper } from './styled'
import InputWrapper from '../InputWrapper'
import ToggleButton from '../@primitives/ToggleButton'

type Option = {
  label: string
  value: string
}

type Props = {
  alignment?: 'row' | 'column'
  className?: string
  conflictMessage?: string
  conflicts?: string[]
  disabled?: boolean
  displayError?: boolean
  gap?: string
  ignoreConflict?: boolean
  label?: string
  mandatory?: boolean
  name: string
  onConflict?: () => void
  options: Option[]
  optionSize?: string
  radius?: string
  scale?: 'small' | 'medium' | 'large'
  type?: 'single' | 'multiple'
}

const ToggleGroup: FC<Props> = (props: Props) => {
  const {
    alignment = 'row',
    conflictMessage,
    conflicts,
    disabled,
    displayError,
    gap,
    ignoreConflict,
    label,
    mandatory,
    name,
    options,
    onConflict,
    radius,
    scale,
    type = 'single',
    optionSize,
    className,
  } = props
  const optionsRef = useRef<HTMLDivElement>(null)

  const { control } = useFormContext()
  const {
    fieldState: { error },
    field: { value, onChange },
  } = useController({
    control,
    name,
    defaultValue: type === 'multiple' ? [] : false,
  })

  const handleClick = (optionValue: string) => {
    if (type === 'multiple') {
      const isActive = value.find((field: string) => field === optionValue)
      const updated = isActive
        ? value.filter((field: string) => field !== optionValue)
        : [...value, optionValue]
      onChange(updated)
    } else {
      onChange(optionValue)
    }
  }

  const handleOptionsKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    const target = event.currentTarget
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowRight': {
        event.preventDefault()
        const nextSibling = target.nextElementSibling as HTMLElement
        if (nextSibling) nextSibling.focus()
        break
      }
      case 'ArrowUp':
      case 'ArrowLeft': {
        event.preventDefault()
        const prevSibling = target.previousElementSibling as HTMLElement
        if (prevSibling) prevSibling.focus()
        break
      }
      case ' ':
      case 'Enter': {
        event.preventDefault()
        const optionValue = options.find(
          (option) => option.label === target.textContent,
        )
        optionValue && handleClick(optionValue.value)
        break
      }
      default:
        break
    }
  }

  return (
    <InputWrapper
      color={'critical'}
      disabled={disabled}
      displayError={displayError}
      error={error?.message}
      label={label}
      mandatory={mandatory}
      size={scale}
      className={className}
    >
      <OptionsWrapper
        alignment={alignment}
        data-testid="options-list"
        disabled={disabled}
        gap={gap}
        ref={optionsRef}
      >
        {options.map((option, index) => {
          const conflict = conflicts?.includes(option.value)
          const previousConflict = conflicts?.includes(
            options[index - 1]?.value &&
              value.find(
                (field: string) => field === options[index - 1]?.value,
              ),
          )
          const nextConflict = conflicts?.includes(
            options[index + 1]?.value &&
              value.find(
                (field: string) => field === options[index + 1]?.value,
              ),
          )
          const hasConflictBeforeOrAfter =
            conflict &&
            (conflict === previousConflict || conflict === nextConflict)

          return (
            <ToggleButton
              isConflict={conflict}
              ignoreConflict={ignoreConflict}
              conflictMessage={conflictMessage}
              data-testid={`option-${option.label}`}
              disabled={disabled}
              handleClick={() => handleClick(option.value)}
              key={option.value}
              label={option.label}
              name={name}
              onKeyDown={handleOptionsKeyDown}
              radius={radius}
              scale={scale}
              toggleValue={option.value}
              value={value}
              width={optionSize}
              hasConflictBeforeOrAfter={hasConflictBeforeOrAfter}
              onConflict={onConflict}
            />
          )
        })}
      </OptionsWrapper>
    </InputWrapper>
  )
}

export default ToggleGroup
