// Models
import { IFilter } from 'heeds-ds/src/components/Inputs/InputSearch'
import {
  IWorkoutRoutine,
  IWorkoutRoutineState,
} from 'storage/workoutRoutine/models'
import { IWorkoutRoutineListPayload } from 'services/workoutRoutine/@types'
import { TModalMoreOption } from 'components/modals/ModalMoreOptions'
import { TRoutineDificulty, TRoutineGoal } from 'models'
import IStore from 'lib/redux/models'

// React
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { convertOptionToFilter } from 'utils/functions'
import { triggerDeleteWorkoutRoutine } from 'storage/workoutRoutine/duck'
import { triggerSendRoutineToAthlete } from 'storage/workoutModel/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Button,
  Chip,
  CommonList,
  DropdownMenu,
  Icon,
  InputSearch,
  Loading,
} from 'heeds-ds'
import {
  ModalAthleteRoutineFilters,
  ModalDelete,
  ModalMoreOptions,
  ModalShareRoutine,
  RoutineItem,
} from 'components'
import Loader from 'components/lists/RoutineItem/loader'

// Assets
import {
  DATE_RANGE_OPTIONS,
  DIFFICULTY_OPTIONS,
  GOAL_OPTIONS,
} from 'utils/constants'

// Constants
const COLUMNS = [
  { label: 'Nome da rotina' },
  { label: 'Período' },
  { label: 'Dias a vencer' },
  { label: 'Objetivo' },
  { label: 'Nível' },
  { label: 'Ações' },
]

const headerGridTemplate =
  'grid-cols-[minmax(240px,1fr)minmax(200px,0.8fr)minmax(200px,0.8fr)minmax(200px,0.8fr)minmax(200px,0.8fr)minmax(10px,0.3fr)]'

const ROUTINE_FILTERS = [
  ...convertOptionToFilter(DATE_RANGE_OPTIONS, 'date_range'),
  ...convertOptionToFilter(DIFFICULTY_OPTIONS, 'difficulty'),
  ...convertOptionToFilter(GOAL_OPTIONS, 'goal'),
]

const ORDER_FILTER = [
  { label: 'Mais recentes', value: 'start_date', param: 'ordering' },
  { label: 'A-Z', value: 'name', param: 'ordering' },
  { label: 'Z-A', value: '-name', param: 'ordering' },
]

export interface IFilterFields {
  goal?: TRoutineGoal
  difficulty?: TRoutineDificulty
  date_range?: string
}

interface Props {
  filters: IWorkoutRoutineListPayload
  handleEndReached: () => void
  handleNavigateToCreation: () => void
  isActive: (name: string, value: string) => boolean
  onClickFilter: (filters: IFilter) => void
  onFiltersChange: (filters: IFilterFields) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  refreshPage: () => void
}

const RoutineList: FC<Props> = ({
  filters,
  handleEndReached,
  handleNavigateToCreation,
  isActive,
  onClickFilter,
  onFiltersChange,
  onSearchChange,
  refreshPage,
}) => {
  const { id = '' } = useParams()
  const { next, loading, workoutRoutines } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const [selected, setSelected] = useState<number | undefined>()

  const hasRoutines = useMemo(
    () =>
      Boolean(workoutRoutines?.length) ||
      ROUTINE_FILTERS.some((item) => isActive(item.param, item.value)),
    [isActive, workoutRoutines?.length],
  )

  const handleSendRoutineToLibrary = useCallback(
    (routine_pk: number) => {
      dispatch(triggerSendRoutineToAthlete({ routine_pk, to_library: true }))
    },
    [dispatch],
  )

  const options: TModalMoreOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => selected && onEditRoutine(selected),
    },
    {
      icon: 'book',
      label: 'Adicionar a biblioteca',
      onClick: () => selected && handleSendRoutineToLibrary(selected),
    },
    {
      icon: 'send',
      label: 'Enviar para um aluno',
      onClick: () => openModal('send-routine-modal'),
    },
    {
      icon: 'delete',
      color: theme.colors.text.critical,
      label: 'Excluir',
      onClick: () => openModal('delete-routine-modal'),
    },
  ]

  const handleFiltersChange = (modalFilters: IFilterFields) => {
    onFiltersChange(modalFilters)
    closeModal()
  }

  const handleShareRoutine = (athletes: number[]) => {
    selected &&
      dispatch(triggerSendRoutineToAthlete({ routine_pk: selected, athletes }))
  }

  const navigateToRoutine = useCallback(
    (routineId: number) => {
      navigate(
        generatePath(urls.workoutRoutine, {
          id,
          routine_id: routineId,
        }),
      )
    },
    [id, navigate],
  )

  const onEditRoutine = useCallback(
    (routineId: number) => {
      navigate(
        generatePath(urls.workoutRoutineEdit, { id, routine_id: routineId }),
      )
      closeModal()
    },
    [closeModal, id, navigate],
  )

  const onRemoveRoutine = (routineId: number) => {
    dispatch(
      triggerDeleteWorkoutRoutine({
        routine_pk: routineId,
        successCallback: () => {
          refreshPage()
        },
      }),
    )
    setSelected(undefined)
  }

  const openFilterModal = () => openModal('routine-filters-modal')

  const orderingFilters = useMemo(
    () =>
      ORDER_FILTER.map((orderItem) => ({
        ...orderItem,
        active: filters?.ordering?.toString() === orderItem.value,
        onClick: () => {
          if (!isDesktop) closeModal()
          onClickFilter(orderItem)
        },
      })),
    [closeModal, filters?.ordering, isDesktop, onClickFilter],
  )

  const renderFilters = useMemo(() => {
    return filters
      ? ROUTINE_FILTERS.map((item) => {
          const active = isActive(item.param, item.value)
          if (active)
            return (
              <Chip
                active
                closeIcon
                key={item.label}
                label={item.label}
                onClick={() => onClickFilter(item)}
                scale="small"
              />
            )
        })
      : null
  }, [filters, isActive, onClickFilter])

  const renderRoutineItems = useCallback(
    (item: IWorkoutRoutine) => {
      return (
        <RoutineItem
          key={item.id}
          onClick={navigateToRoutine}
          routine={item}
          onRemove={() => {
            setSelected(item.id)
            openModal('delete-routine-modal')
          }}
          onEdit={onEditRoutine}
          onShare={() => {
            setSelected(item.id)
            openModal('send-routine-modal')
          }}
          sendToLibrary={() => handleSendRoutineToLibrary(item.id)}
          openOptions={() => {
            setSelected(item.id)
            openModal('more-options-routine-modal')
          }}
        />
      )
    },
    [handleSendRoutineToLibrary, navigateToRoutine, onEditRoutine, openModal],
  )

  if (loading && !workoutRoutines) {
    return <Loading active />
  }

  return (
    <Styled.Content id="athlete-routines-content">
      <Styled.SearchContainer>
        <InputSearch
          onChange={onSearchChange}
          onClick={openFilterModal}
          placeholder="Pesquisar rotinas"
          scale="small"
          value={filters?.search ?? ''}
          className={isDesktop ? 'm-0 w-[375px]' : 'm-0 w-full'}
        />

        {!isDesktop && (
          <Styled.OrderingButton
            onClick={() => openModal('ordering-filter-modal')}
            track={buttonClickTracking}
            trackName="open_mobile_ordering_filters_modal"
          />
        )}
      </Styled.SearchContainer>

      <Styled.FiltersContainer>
        {hasRoutines && (
          <Styled.FiltersScrollContainer>
            <Aligner gap="1.6rem">{renderFilters}</Aligner>
          </Styled.FiltersScrollContainer>
        )}

        {isDesktop && (
          <DropdownMenu items={orderingFilters}>
            <Button
              size="xsmall"
              track={buttonClickTracking}
              trackName="open_ordering_filters_menu"
              variation="borderless"
            >
              <Icon
                color={theme.colors.interactive.default}
                iconName="swapVert"
              />
              Ordenar
            </Button>
          </DropdownMenu>
        )}
      </Styled.FiltersContainer>

      {hasRoutines ? (
        <CommonList<IWorkoutRoutine>
          columnsFormat={headerGridTemplate}
          columns={COLUMNS}
          data={workoutRoutines || []}
          onEndReached={handleEndReached}
          loaderComponent={<Loader />}
          padding="py-4 px-6"
          showHeader={isDesktop}
          renderItem={renderRoutineItems}
          hasMore={!!next}
        />
      ) : (
        <Styled.EmptyContainer isDesktop={isDesktop}>
          <Styled.EmptyText>
            Você ainda não cadastrou nenhum treino novo.
          </Styled.EmptyText>

          <Styled.GymEmpty />
        </Styled.EmptyContainer>
      )}

      {!isDesktop && (
        <Styled.ContainerBottom>
          <Button
            onClick={handleNavigateToCreation}
            size="small"
            track={buttonClickTracking}
            trackName="navigate_to_create_routine"
            className="w-full"
          >
            <Styled.AddIcon />
            Adicionar rotina
          </Button>
        </Styled.ContainerBottom>
      )}

      {!isDesktop && isVisible === 'more-options-routine-modal' && (
        <ModalMoreOptions options={options} />
      )}

      {!isDesktop && isVisible === 'ordering-filter-modal' && (
        <ModalMoreOptions options={orderingFilters} />
      )}

      {isVisible === 'send-routine-modal' && (
        <ModalShareRoutine onSend={handleShareRoutine} />
      )}

      {isVisible === 'routine-filters-modal' && (
        <ModalAthleteRoutineFilters
          handleCancel={() => closeModal()}
          onConfirm={handleFiltersChange}
          type="atlhete"
        />
      )}

      {isVisible === 'delete-routine-modal' && (
        <ModalDelete
          confirmTitle="Excluir"
          description="Tem certeza que deseja excluir esta rotina?"
          onConfirm={() => selected && onRemoveRoutine(selected)}
          title="Excluir Rotina"
        />
      )}
    </Styled.Content>
  )
}

export default RoutineList
