// Models
import { IModelInput } from 'models'

// React
import { FC, useCallback, useContext } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useMediaQuery, useModal, useToast } from 'hooks'

// Components
import * as Styled from './styled'
import { FormButton, InputText } from 'heeds-ds'
import Modal from '../Modal'

type FormFields = {
  index: number
  name: string
}

interface Props {
  updateWorkoutModel: (id: string, updatedModel: IModelInput) => void
  workoutModel?: IModelInput
}

const ModalRenameWorkoutModel: FC<Props> = ({
  updateWorkoutModel,
  workoutModel,
}) => {
  const { breakpoints } = useContext(ThemeContext)
  const { closeModal } = useModal()
  const { openToast } = useToast()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const formMethods = useForm<FormFields>({
    defaultValues: { name: workoutModel?.name ?? '' },
  })
  const { handleSubmit } = formMethods

  const onSubmit = useCallback(
    (formData: FormFields) => {
      if (workoutModel) {
        updateWorkoutModel(workoutModel.id, {
          ...workoutModel,
          name: formData.name,
        })
        closeModal()
        openToast({
          type: 'success',
          customTitle: 'Sucesso!',
          message: 'Seu treino foi renomeado.',
        })
      }
    },
    [closeModal, openToast, workoutModel, updateWorkoutModel],
  )

  return (
    <Modal>
      <Styled.Container>
        <Styled.Header>
          <Styled.Title>Renomear Treino</Styled.Title>
          <Styled.Description>
            Você pode escolher outro nome para o seu treino.
          </Styled.Description>
        </Styled.Header>

        <FormProvider {...formMethods}>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Styled.Content>
              <InputText label="Nome do treino" name="name" scale="small" />
            </Styled.Content>

            <Styled.FooterActions>
              <Styled.CancelButton
                size={isDesktop ? 'xsmall' : 'small'}
                track={buttonClickTracking}
                trackName="cancel_update_workout_model_name"
                onClick={closeModal}
              >
                Cancelar
              </Styled.CancelButton>
              <FormButton
                size={isDesktop ? 'xsmall' : 'small'}
                track={buttonClickTracking}
                trackName="update_workout_model_name"
                className={isDesktop ? 'w-fit' : 'w-full'}
              >
                Salvar alterações
              </FormButton>
            </Styled.FooterActions>
          </Styled.Form>
        </FormProvider>
      </Styled.Container>
    </Modal>
  )
}

export default ModalRenameWorkoutModel
