// Models
import { IAuthState } from 'storage/auth/models'
import { IFinancialState } from 'storage/financial/models'
import { IFinancialSubscriptionList } from 'services/financialSubscription/@types'
import { IFinancialSubscriptionState } from 'storage/financialSubscription/models'
import { TFinancialOverviewStatus, TPlanPeriodicity } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useMemo, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

// Misc
import { BRL } from 'lib/currency'
import { cn } from 'utils/helpers/classess'
import { getReferenceMonthByTimeInterval } from 'utils/helpers/date'
import { triggerLoadFinancialOverview } from 'storage/financial/duck'
import {
  triggerLoadFinancialSubscriptions,
  triggerLoadMoreFinancialSubscriptions,
} from 'storage/financialSubscription/duck'
import { urls } from 'routes/paths'
import { useDebounceFunction, useFilters, useMediaQuery, useMenu } from 'hooks'

// Components
import {
  Aligner,
  Button,
  Chip,
  CommonList,
  Icon,
  InputSearch,
  Loading,
} from 'heeds-ds'
import {
  FinancialAmountReceived,
  FinancialAthleteItem,
  FinancialInfoTag,
  FinancialNotices,
  LoaderFinancialAthleteItem,
} from 'components'

// Constants
import { PERIODICITY_FILTERS, SUBSCRIPTION_FILTERS } from 'utils/constants'

const COLUMNS = [
  { label: 'Nome' },
  { label: 'Plano' },
  { label: 'Próximo vencimento' },
  { label: 'Status' },
  { label: 'Valor do plano' },
  { label: '' },
]

const page_size = 20

interface IFilters {
  athlete_name?: string
  payment_status?: 0 | 1 | 2 | 3
  status?: TFinancialOverviewStatus
  time_interval?: TPlanPeriodicity
}

const FinancialGeneral: FC = () => {
  const { metrics, loading, overview } = useSelector<IStore, IFinancialState>(
    (state) => state.financial,
  )
  const {
    count,
    loading: loadingSubscriptions,
    next,
    subscriptions,
  } = useSelector<IStore, IFinancialSubscriptionState>(
    (state) => state.financialSubscription,
  )
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { setPagename } = useMenu()
  const { breakpoints } = useContext(ThemeContext)
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { setFilter, filters, isValueIncluded, toggleFilter } =
    useFilters<IFilters>(searchParams)

  const hasActiveFilters = useMemo(
    () =>
      SUBSCRIPTION_FILTERS.some(({ param, value }) =>
        isValueIncluded?.(param, value),
      ),
    [isValueIncluded],
  )
  const [showFilters, setShowFilters] = useState(hasActiveFilters)

  const referenceLabel = useMemo(() => {
    const timeInterval = filters.time_interval || 'monthly'
    return getReferenceMonthByTimeInterval(timeInterval)
  }, [filters.time_interval])

  const percentOfNewAthletes = useMemo(() => {
    if (!overview) return 0
    const { previous_total_athletes: previous, total_athletes: total } =
      overview
    if (!previous || !total) return 0
    const result = (100 * total) / previous - 100
    return Math.round(result * 100) / 100
  }, [overview])

  const navigateToAthleteFinancialProfile = (subscriptionId: number) =>
    navigate(generatePath(urls.financialAthlete, { id: subscriptionId }))

  const renderItem = (item: IFinancialSubscriptionList) => {
    return (
      <FinancialAthleteItem
        key={item.id}
        subscription={item}
        onClick={() => navigateToAthleteFinancialProfile(item.id)}
        onChangeAthelteStatus={() => handleReloadFinancialOverview(filters)}
      />
    )
  }

  const onClickFilter = (param: string, value: string) => {
    const newFilters = toggleFilter(param, value, true)
    handleReloadFinancialOverview(newFilters)
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('athlete_name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleEndReached = () => {
    if (!loadingSubscriptions && subscriptions && next && userData?.profileId) {
      const query = {
        ...filters,
        page: next,
        page_size,
        profile_pk: userData?.profileId,
      }
      dispatch(triggerLoadMoreFinancialSubscriptions(query))
    }
  }

  const handleReloadFinancialOverview = (newFilters: IFilters) => {
    if (userData?.profileId) {
      const query = {
        ...newFilters,
        page_size,
        profile_pk: userData?.profileId,
      }
      dispatch(triggerLoadFinancialOverview(query))
      dispatch(triggerLoadFinancialSubscriptions(query))
    }
  }

  const debouncedLoad = useDebounceFunction<(newFilters: IFilters) => void>(
    handleReloadFinancialOverview,
    1000,
  )

  useLayoutEffect(() => {
    setPagename('Financeiro')
    return () => setPagename('Dashboard')
  }, [setPagename])

  useLayoutEffect(() => {
    if (userData?.profileId) {
      const payload = {
        ...filters,
        page_size,
        profile_pk: userData.profileId,
      }

      dispatch(triggerLoadFinancialOverview(payload))
      dispatch(triggerLoadFinancialSubscriptions(payload))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userData?.profileId])

  if (!subscriptions && (loading || loadingSubscriptions)) {
    return <Loading active />
  }

  return (
    <div className="flex flex-1 flex-col gap-8 p-6 xl:gap-6 xl:pb-0 xl:pl-10 xl:pr-12 xl:pt-10">
      <div className="flex justify-between gap-2 sm:justify-end">
        {PERIODICITY_FILTERS?.map((filter) => {
          const active = isValueIncluded?.(filter.param, filter.value)
          return (
            <Chip
              active={active}
              key={filter.value}
              label={filter.label}
              onClick={() => onClickFilter(filter.param, filter.value)}
              scale={isDesktop ? 'small' : 'large'}
            />
          )
        })}
      </div>

      <div className="grid grid-cols-1 gap-4 xl:grid-cols-financial-1 xl:gap-6">
        <Aligner flex="column" gap="2.4rem">
          <FinancialInfoTag
            reference={referenceLabel}
            title="Alunos"
            value={`${overview?.total_athletes ?? 0}`}
            percent={percentOfNewAthletes}
          />

          {isDesktop && (
            <FinancialInfoTag
              reference={referenceLabel}
              title="Valor estimado"
              value={BRL(overview?.expected_total_price).format()}
            />
          )}
        </Aligner>

        <FinancialAmountReceived
          received={overview?.total_paid}
          reference={referenceLabel}
          toReceive={overview?.still_to_be_paid}
        />

        {isDesktop && <FinancialNotices notices={metrics?.notices} />}
      </div>

      <div
        className={cn(
          'flex flex-1 flex-col rounded-[8px] border-border-input',
          'xl:border xl:bg-surface xl:shadow-sm',
        )}
      >
        <div className="flex w-full flex-col items-end justify-between gap-4 py-4 xl:flex-row xl:gap-6 xl:px-6">
          {isDesktop && (
            <div className="flex flex-col gap-1">
              <p className="text-copy1 font-semibold leading-9 text-text">
                Alunos
              </p>
              <p className="text-copy3 leading-7 text-text-subdued">
                {overview?.total_athletes} aluno
                {overview?.total_athletes === 1 ? '' : 's'}
              </p>
            </div>
          )}

          <InputSearch
            onChange={onSearchChange}
            scale="small"
            value={filters.athlete_name ?? ''}
            className={isDesktop ? 'ml-auto w-[375px]' : 'ml-auto w-full'}
          />

          <Button
            onClick={() => setShowFilters((currentValue) => !currentValue)}
            size="xsmall"
            variation="outlined"
          >
            <Icon iconName="filter" />
            Filtro
          </Button>
        </div>

        {showFilters && (
          <div className="no-scrollbar flex flex-wrap gap-2 py-2 lg:px-6">
            {SUBSCRIPTION_FILTERS.map((filter) => {
              const isActive = isValueIncluded?.(filter.param, filter.value)
              return (
                <Chip
                  active={isActive}
                  key={filter.label}
                  label={filter.label}
                  onClick={() => onClickFilter(filter.param, filter.value)}
                  scale="small"
                />
              )
            })}
          </div>
        )}

        {count ? (
          <CommonList<IFinancialSubscriptionList>
            columns={COLUMNS}
            columnsFormat="grid grid-cols-[1.5fr_repeat(2,0.75fr_1fr)_68px]"
            data={subscriptions || []}
            hasMore={!!next}
            loaderComponent={<LoaderFinancialAthleteItem />}
            onEndReached={handleEndReached}
            padding="py-3 px-4"
            renderItem={renderItem}
            scrollableDiv="dashboard-content"
            showHeader={isDesktop}
          />
        ) : (
          <div className="flex flex-1">
            <p className="m-auto text-copy4 text-text-subdued">
              {Object.values(filters).length
                ? 'Nenhum dado encontrado.'
                : 'Ainda não há dados para mostrar nessa área.'}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default FinancialGeneral
