// Models
import { IconsType } from '../../../../components/UI/Icons/values'

// React
import React, { forwardRef } from 'react'

// Misc
import { cn } from '../../../../utils/classes'

// Components
import Icon from '../../../UI/Icons'

type InputAttrs = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>

type Props = InputAttrs & {
  'data-testid'?: string
  isFocused?: boolean
  scale?: 'large' | 'medium'
  iconName?: IconsType
}

const InputCheckbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      checked,
      scale = 'medium',
      disabled,
      iconName = 'checkmark',
      isFocused,
      ...attributes
    },
    ref,
  ) => (
    <div
      aria-checked={checked}
      aria-disabled={disabled}
      data-testid="styled-input"
      role="checkbox"
      className={cn(
        'relative w-[18px] rounded-[3px] border-2 border-icon transition-all',
        'aria-disabled:pointer-events-none aria-disabled:border-icon-disabled',
        'aria-checked:bg-icon aria-checked:hover:bg-icon-hovered',
        'aria-disabled:aria-checked:bg-icon-disabled',
        'h-[18px] duration-300 ease-in-out',
        'hover:border-icon-hovered',
        {
          'w-6 h-6': scale === 'large',
          'border-focusedDefault aria-checked:bg-focusedDefault': isFocused,
        },
      )}
    >
      <div
        className={cn(
          'absolute left-1/2 top-1/2 w-[18px] -translate-x-1/2',
          'h-[18px] -translate-y-1/2',
          { 'w-6 h-6': scale === 'large' },
        )}
      >
        <Icon
          size={scale === 'medium' ? 18 : 24}
          color={checked ? 'white' : '#00000000'}
          iconName={iconName}
        />
      </div>
      <input
        {...attributes}
        checked={checked}
        disabled={disabled}
        ref={ref}
        type="checkbox"
        className="absolute appearance-none opacity-0"
      />
    </div>
  ),
)
InputCheckbox.displayName = 'InputCheckbox'

export default InputCheckbox
