// React
import { FC, useContext, useState } from 'react'

// Models
import { IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// Libraries
import { FormProvider } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { cleanup, newPassword } from 'storage/auth/duck'
import { profilePasswordSchema } from 'schemas'
import { useModal } from 'hooks'
import useGlobalForms from 'hooks/useGlobalForms'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { FooterActionButton, NewContentBox } from 'components'
import { InputText, PasswordValidator } from 'heeds-ds'
import { ModalAlert, ModalConfirmation } from 'components/modals'

export interface FormInputs {
  newPassword: string
}

const ProfilePasswordChange: FC = () => {
  const { error, alert } = useSelector<IStore, IAuthState>(
    (state) => state.auth,
  )
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [passwordValid, setPasswordValid] = useState<boolean | undefined>()
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
  })

  const formMethods = useGlobalForms<FormInputs>({
    schema: profilePasswordSchema,
  })
  const {
    formState: { isValid },
    handleSubmit,
    watch,
  } = formMethods
  const formValues = watch()

  const submitDisabled = !isValid || !passwordValid

  const onSubmit = (data: FormInputs) => {
    setPasswordData({
      newPassword: data.newPassword,
    })
    dispatch(cleanup())
    openModal('password-change-modal')
  }

  const sendNewPassWord = () => {
    dispatch(newPassword(passwordData))
  }

  return (
    <Styled.Container>
      <FormProvider {...formMethods}>
        <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
          <NewContentBox
            padding={isDesktop ? '0 0 13rem' : ''}
            title="Senha"
            description="Nessa aba, é possível trocar sua senha caso tenha esquecido ou precise escolher outra."
          >
            <Styled.FormCardInner>
              {/* TODO: set error here */}
              <InputText
                label="Nova Senha"
                name="newPassword"
                placeholder="Digite sua nova senha"
                scale="small"
                type="password"
                className={isDesktop ? 'mb-10 mt-8 w-[570px]' : 'mb-10 mt-6'}
              />
              <PasswordValidator
                value={formValues.newPassword}
                callback={setPasswordValid}
              />
            </Styled.FormCardInner>
          </NewContentBox>
          <FooterActionButton
            disabled={submitDisabled}
            primaryTitleButton="Salvar"
          />
        </Styled.FormContainer>
      </FormProvider>

      {alert && <ModalAlert error={Boolean(error)} />}
      {!alert && (
        <ModalConfirmation
          title="Salvar Senha"
          description="Tem certeza que deseja alterar a sua senha?"
          onConfirm={sendNewPassWord}
        />
      )}
    </Styled.Container>
  )
}

export default ProfilePasswordChange
