// React
import { useMemo } from 'react'

// Misc
import { cn } from '../../../../utils/classes'
import { toggleButtonStyles, labelStyles } from './styles'

// Components
import { Icon } from '../../../index'

type ToggleAttrs = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>

type Props = ToggleAttrs & {
  'data-testid'?: string
  conflictMessage?: string
  handleClick?: (active: boolean) => void
  hasConflictBeforeOrAfter?: boolean
  isConflict?: boolean
  ignoreConflict?: boolean
  label?: string
  onConflict?: () => void
  radius?: string
  scale?: 'small' | 'medium' | 'large'
  toggleValue: string | string[]
  value?: string | readonly string[]
  width?: string
  className?: string
}

export type TToggleColors = {
  background: 'critical' | 'secondary' | 'onPrimary'
  border: 'criticalDefault' | 'default' | 'input'
  hoverBackground: 'critical' | 'hovered' | 'onPrimary'
  hoverBorder: 'critical' | 'hovered' | 'disabled'
}

const ToggleButton = ({
  conflictMessage,
  handleClick,
  hasConflictBeforeOrAfter,
  label,
  onConflict,
  ignoreConflict,
  radius,
  scale = 'medium',
  toggleValue,
  value,
  width,
  isConflict,
  className,
  ...attributes
}: Props) => {
  const isChecked = useMemo(
    () =>
      Array.isArray(value)
        ? value.includes(toggleValue)
        : value === toggleValue,
    [toggleValue, value],
  )

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isConflict && isChecked && !ignoreConflict) {
      return onConflict?.()
    }
    event.stopPropagation()
    handleClick?.(!isChecked)
  }

  const handleBackgroundColor = (
    active: boolean,
    conflict?: boolean,
  ): 'default' | 'activePlusConflict' | 'active' => {
    switch (true) {
      case active && conflict:
        return 'activePlusConflict'
      case active:
        return 'active'
      default:
        return 'default'
    }
  }

  return (
    <div className={cn('flex items-center xl:flex-col', className)}>
      <button
        aria-pressed={isChecked}
        onClick={onClick}
        {...attributes}
        type="button"
        className={cn(
          toggleButtonStyles({
            colors: handleBackgroundColor(isChecked, isConflict),
            scale,
          }),
          {
            'disabled:bg-icon-onPrimary': isChecked,
            'ml-[-20%]': isConflict && isChecked && !!conflictMessage,
          },
          width,
          radius,
        )}
      >
        <span
          className={cn(labelStyles({ fontSize: scale, active: isChecked }))}
        >
          {label}
        </span>
      </button>
      {isConflict && isChecked && conflictMessage && (
        <div
          className={cn(
            'flex items-center justify-center xl:absolute xl:top-full xl:mt-0',
            'xl:h-[140px] xl:w-[120px] xl:items-start',
            { 'xl:w-[64px]': hasConflictBeforeOrAfter },
          )}
        >
          <div
            className={cn(
              'ml-4 mr-2 flex items-center justify-center rounded-[10px]',
              'bg-icon-critical p-[10px] xl:hidden',
            )}
          >
            <Icon iconName="warningSolid" className="fill-text-onPrimary" />
          </div>
          <p className="text-left text-copy4 text-text-critical xl:text-center">
            {conflictMessage}
          </p>
        </div>
      )}
    </div>
  )
}

export default ToggleButton
