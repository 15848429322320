// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  EWorkoutDifficulty,
  EWorkoutGoal,
  IFormInputs,
  IModelInput,
  IWorkoutSetInput,
  TFormValues,
  TMuscleGroupFilter,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'
import { IWorkoutRoutineFormData } from '../index'
import { TSetStateModels } from 'hooks/useWorkoutModelsForm/@types'
import IStore from 'lib/redux/models'

// React
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

// Libraries
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { triggerToastError } from 'storage/general/duck'
import { useModal } from 'hooks'

// Components
import * as Blocks from 'blocks/dashboard/workout/routine'
import * as Styled from './styled'
import { InputText } from 'heeds-ds'
import { ModalExerciseImage } from 'components/modals'

// Constants
const TOAST_INFO = {
  bothErrors: {
    customTitle: 'Faltam informações no treino',
    message:
      'Série é um campo obrigatório. Evite treinos vazios, adicione exercícios para revisar.',
    time: 10000,
  },
  emptyWorkout: {
    customTitle: 'Faltam informações no treino',
    message: 'Evite treinos vazios, adicione exercícios para revisar.',
  },
  fieldRequired: {
    customTitle: 'Série é um campo obrigatório!',
    message:
      'Lembre-se de adicionar essa informação no exercício para o seu aluno.',
  },
}

type Props = {
  addOrRemoveFieldFromWorkoutSetToExercise: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  duplicateWorkoutModel: () => void
  filters: TMuscleGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  methods: UseFormReturn<IFormInputs, TFormValues>
  onChangeFilters: (filters: TMuscleGroupFilter[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeFilter: (label: string) => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeWorkoutModel: () => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  renameWorkoutModel: () => void
  replicateWorkoutSetFields: (modelId: string, set: IWorkoutSetInput) => void
  routineMethods: UseFormReturn<IWorkoutRoutineFormData, string | number>
  selectedModelIndex: number
  selectWorkoutModel: (index: number) => void
  setSpecialSet: TSetSpecialSet
  setStateModels: TSetStateModels
  specialSet?: TSpecialSetInput
  stateModels: IModelInput[]
  openWorkoutModelTemplateModal: () => void
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const Desktop: FC<Props> = ({
  addExerciseToWorkoutSet,
  addOrRemoveFieldFromWorkoutSetToExercise,
  addWorkoutModel,
  addWorkoutSet,
  duplicateWorkoutModel,
  filters,
  inputValue,
  loadMoreExercises,
  methods,
  onChangeFilters,
  onSearchChange,
  removeExerciseFromWorkoutSet,
  removeFilter,
  removeWorkoutSet,
  replicateWorkoutSetFields,
  setSpecialSet,
  updateWorkoutSet,
  routineMethods,
  ...commonProps
}) => {
  const { workoutRoutineTemplate } = useSelector(
    (state: IStore) => state.workoutRoutine,
  )
  const { specialSet, stateModels } = commonProps
  const { closeModal, isVisible, openModal } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()

  const {
    formState: { errors, isValid },
  } = methods

  const hasEmptyModels = useMemo(
    () =>
      stateModels.some((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length === 0),
      ),
    [stateModels],
  )

  const isDisabled = specialSet !== undefined

  const toastType = useMemo(() => {
    let emptyErrors = 0
    let fieldErrors = 0

    if (errors?.models?.type === 'someExercise') return 'someExercise'

    errors.models?.forEach?.((fieldModel) => {
      if (fieldModel?.name) return (emptyErrors += 1)
      return (fieldErrors += 1)
    })

    if (fieldErrors && emptyErrors) return 'bothErrors'

    if (emptyErrors) return 'emptyWorkout'

    return 'fieldRequired'
  }, [errors?.models])

  const showToast = useCallback(() => {
    if (!isValid && toastType !== 'someExercise') {
      dispatch(triggerToastError({ ...TOAST_INFO[toastType] }))
    }
  }, [dispatch, isValid, toastType])

  const goBack = () => navigate(-1)

  const openExerciseGifModal = (exercise: IExerciseData) => {
    setSelectedExercise(exercise)
    openModal('desktop-exercise-gif-modal')
  }

  const closeExerciseGifModal = () => {
    setSelectedExercise(undefined)
    closeModal()
  }

  useEffect(() => {
    if (Object.keys(errors).length) showToast()
  }, [errors, showToast])

  return (
    <div className="flex w-full flex-1 flex-col">
      <div className="relative flex flex-1 gap-5">
        <div className="flex h-[calc(100vh-140px)] w-[452px] max-w-[452px] flex-1 flex-col gap-4">
          <div className="flex min-h-[178px] flex-col gap-2 rounded-[8px] border border-border-input bg-surface px-4 py-6">
            <FormProvider {...routineMethods}>
              <InputText
                name="name"
                scale="small"
                label="Nome do modelo"
                placeholder="Digite o nome do modelo da rotina"
              />
            </FormProvider>

            <div className="grid grid-cols-2 gap-2">
              <div className="flex flex-col gap-1">
                <p className="text-copy4 font-semibold">Objetivo</p>
                <p className="text-copy4">
                  {workoutRoutineTemplate?.goal
                    ? EWorkoutGoal[workoutRoutineTemplate.goal]
                    : null}
                </p>
              </div>

              <div className="flex flex-col gap-1">
                <p className="text-copy4 font-semibold">Nível de dificuldade</p>
                <p className="text-copy4">
                  {workoutRoutineTemplate?.difficulty
                    ? EWorkoutDifficulty[workoutRoutineTemplate.difficulty]
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div
            className={cn(
              'flex h-[calc(100vh-332px)] max-h-[calc(100vh-332px)] rounded-t-[8px]',
              'border border-b-0 border-border-input bg-surface',
            )}
          >
            <Blocks.WorkoutExercises
              {...commonProps}
              addExerciseToWorkoutSet={addExerciseToWorkoutSet}
              addWorkoutSet={addWorkoutSet}
              filters={filters}
              inputValue={inputValue}
              loadMoreExercises={loadMoreExercises}
              onChangeFilters={onChangeFilters}
              onClickFilter={() => openModal('exercises-filter-modal')}
              onSearchChange={onSearchChange}
              openExerciseGifModal={openExerciseGifModal}
              removeFilter={removeFilter}
              stateModels={stateModels}
            />
          </div>
        </div>

        <div
          className={cn(
            'flex h-full max-h-[calc(100vh-140px)] flex-1 rounded-t-[8px]',
            'border border-b-0 border-border-input bg-surface',
          )}
        >
          <Blocks.WorkoutModels
            {...commonProps}
            addOrRemoveFieldFromWorkoutSetToExercise={
              addOrRemoveFieldFromWorkoutSetToExercise
            }
            addWorkoutModel={addWorkoutModel}
            addWorkoutSet={addWorkoutSet}
            duplicateWorkoutModel={duplicateWorkoutModel}
            methods={methods}
            openExerciseGifModal={openExerciseGifModal}
            removeExerciseFromWorkoutSet={removeExerciseFromWorkoutSet}
            removeWorkoutSet={removeWorkoutSet}
            replicateWorkoutSetFields={replicateWorkoutSetFields}
            setSpecialSet={setSpecialSet}
            stateModels={stateModels}
            updateWorkoutSet={updateWorkoutSet}
          />
        </div>
      </div>

      <div
        className={cn(
          'z-[1] flex w-full justify-between rounded-b-[8px]',
          'border border-border-input bg-surface px-10 py-2',
        )}
      >
        <Styled.CancelButton
          disabled={isDisabled}
          onClick={goBack}
          track={buttonClickTracking}
          trackName="navigate_to_athlete_routines"
        >
          Cancelar
        </Styled.CancelButton>

        <Styled.NextButton
          disabled={isDisabled || !isValid}
          form="sets-form"
          onClick={() =>
            openModal(hasEmptyModels ? 'empty-model' : 'go-to-review-modal')
          }
          track={buttonClickTracking}
          trackName="update_state_and_navigate_to_workout_review"
        >
          Ir para revisão
        </Styled.NextButton>
      </div>

      {isVisible === 'desktop-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeExerciseGifModal}
        />
      )}
    </div>
  )
}

export default Desktop
