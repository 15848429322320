// Libs
import { cva } from 'class-variance-authority'

export const toggleButtonStyles = cva(
  [
    'group inline-flex w-min cursor-pointer items-center justify-center gap-1 rounded-[15px] border',
    'border-border-input bg-text-onPrimary ml-0 xl:ml-0 focus:shadow-xl',
    'disabled:bg-icon-onPrimary',
  ],
  {
    variants: {
      colors: {
        active:
          'border-border bg-text-secondary xl:hover:border-icon-hovered xl:hover:bg-icon-hovered',
        activePlusConflict:
          'border-border-criticalDefault bg-text-critical xl:hover:border-icon-critical xl:hover:bg-icon-critical',
        default:
          'border-border-input bg-text-onPrimary xl:hover:border-icon-disabled xl:hover:bg-icon-onPrimary',
      },
      scale: {
        small: 'px-4 py-2',
        medium: 'p-4',
        large: 'px-4 py-6',
      },
    },
    defaultVariants: {
      colors: 'active',
      scale: 'medium',
    },
  },
)

export const labelStyles = cva(
  [
    'whitespace-nowrap font-bold uppercase text-text group-disabled:text-text-disabled group-hover:text-text-primary',
  ],
  {
    variants: {
      fontSize: {
        small: 'text-small',
        medium: 'text-copy5',
        large: 'text-copy3',
      },
      active: {
        true: 'text-text-onPrimary group-disabled:text-text-onPrimary group-hover:text-text-onPrimary',
      },
    },
    defaultVariants: {
      fontSize: 'medium',
    },
  },
)
