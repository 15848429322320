// Models
import { IFinancialState } from 'storage/financial/models'
import IStore from 'lib/redux/models'

// React
import React, { useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

// Misc
import { BRL } from 'lib/currency'
import { useModal } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Aligner, Body, InputText, Switch, Tag } from 'heeds-ds'
import { Modal } from 'components/modals'

type TValue = 'monthly' | 'quarterly' | 'semiannual' | 'yearly'

type TInitialValues = {
  monthly?: { [key: string]: number }
  quarterly?: { [key: string]: number }
  semiannual?: { [key: string]: number }
  yearly?: { [key: string]: number }
}

type DataProps = {
  id: number
  label: string
  value: TValue
}
interface Props {
  data: DataProps[]
  submit: () => void
}

const CustomPlanModal: React.FC<Props> = ({ data, submit }) => {
  const { planList } = useSelector<IStore, IFinancialState>(
    ({ financial }) => financial,
  )

  const { closeModal } = useModal()
  const { watch } = useFormContext()

  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const frequencies = [1, 2, 3, 4, 5, 6, 7]

  const handleSave = () => {
    closeModal()
    submit()
  }

  const initialValues = planList?.results.reduce(
    (accumulator: TInitialValues, value) => {
      if (value.value.custom) {
        accumulator[value.plan_periodicity] = value.value.custom
      }
      return accumulator
    },
    {},
  )

  return (
    <Modal>
      <Styled.Container>
        <Styled.Title>Crie seu plano personalizado</Styled.Title>
        <Styled.Subtitle>
          Selecione o período que você oferece para os seus alunos, depois
          digite o valor para cada frequência. Você pode ativar e desativar
          esses valores no momento que desejar.
        </Styled.Subtitle>
        <Styled.PlanTypeContainer>
          {data.map((type, index) => {
            const hasActive = watch(
              `${type.value}.in_person.has_active_subscriptions`,
            )

            return (
              <Tag
                key={index}
                active={Boolean(watch(`${type.value}.in_person.active`))}
                borderPosition={isDesktop ? 'top' : 'left'}
                margin={isDesktop ? '0' : '0 0 32px 0'}
              >
                <Aligner flex="column">
                  <Styled.PlanTypeTagHeader>
                    <Switch name={`${type.value}.in_person.active`} />
                    <Body weight={700} color={theme.colors.text.secondary}>
                      {type.label}
                    </Body>
                  </Styled.PlanTypeTagHeader>
                  {Boolean(watch(`${type.value}.in_person.active`)) && (
                    <Styled.FrequencyContainer>
                      {frequencies.map((frequency) => {
                        const initialPrice =
                          initialValues?.[type.value]?.[frequency]
                        const currentPrice = BRL(
                          watch(
                            `${type.value}.in_person.value.custom.[${
                              frequency - 1
                            }]`,
                          ),
                        ).value
                        return (
                          <Aligner
                            key={frequency}
                            flex="column"
                            gap="16px"
                            align="center"
                          >
                            <Body type="copy3">
                              {frequency} {frequency === 1 ? 'vez' : 'vezes'}
                              /semana
                            </Body>
                            <InputText
                              mask="currency"
                              name={`${type.value}.in_person.value.custom.[${
                                frequency - 1
                              }]`}
                              scale="small"
                              displayError={false}
                            />
                            {hasActive && initialPrice !== currentPrice && (
                              <Body
                                type="copy5"
                                color={theme.colors.text.highlight}
                              >
                                Esse valor será modificado na próxima renovação
                                do aluno e para novos alunos.
                              </Body>
                            )}
                          </Aligner>
                        )
                      })}
                    </Styled.FrequencyContainer>
                  )}
                </Aligner>
              </Tag>
            )
          })}
        </Styled.PlanTypeContainer>
        <Styled.ButtonContainer>
          <Styled.SaveButton onClick={handleSave}>Salvar</Styled.SaveButton>
        </Styled.ButtonContainer>
      </Styled.Container>
    </Modal>
  )
}

export default CustomPlanModal
