// Models
import {
  IAuthState,
  IForgotPasswordPayload,
  IResetPasswordPayload,
} from 'storage/auth/models'
import { TOutletContext } from 'layouts/AuthLayout'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useState } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { authForgotPasswordSchema } from 'schemas'
import { cn } from 'utils/helpers/classess'
import {
  cleanup,
  requestForgotPassword,
  resetPassword,
  validatePasswordToken,
} from 'storage/auth/duck'
import { urls } from 'routes/paths'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Button,
  FormButton,
  InputText,
  LinkButton,
  Loading,
  PasswordValidator,
} from 'heeds-ds'

// Assets
import backgroundImage from 'assets/images/signup-confirmation-background.svg'
import doubleCheck from 'assets/images/done-all.png'
import emailFail from 'assets/images/email-fail.png'

export interface FormInputs {
  password: string
}

const ResetPassword: FC = () => {
  const { isResetTokenValid, loading, resetPasswordSuccess, userData } =
    useSelector<IStore, IAuthState>((state) => state.auth)
  const { setLayoutBackground } = useOutletContext<TOutletContext>()
  const { token } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktopSmall}px)`,
  )

  const [passwordValid, setPasswordValid] = useState<boolean | undefined>()

  const formMethods = useForm<FormInputs>({
    resolver: yupResolver(authForgotPasswordSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const {
    formState: { isValid },
    handleSubmit,
    watch,
  } = formMethods

  const submitDisabled = !isValid || !passwordValid

  const onSubmit = ({ password }: FormInputs) => {
    const payload: IResetPasswordPayload = {
      password: password,
      token: token ?? '',
    }

    if (token) dispatch(resetPassword(payload))
  }

  const handleResendEmail = () => {
    const payload: IForgotPasswordPayload = {
      email: userData?.email ?? '',
    }
    dispatch(requestForgotPassword(payload))
  }

  useLayoutEffect(() => {
    setLayoutBackground(backgroundImage)
    if (token) dispatch(validatePasswordToken(token))

    return () => {
      dispatch(cleanup())
    }
  }, [dispatch, setLayoutBackground, token])

  const handleDisplayContent = () => {
    if (resetPasswordSuccess) {
      return (
        <Styled.Container>
          <Styled.Image src={doubleCheck} />

          <Styled.MainTitle>Sua senha foi redefinida</Styled.MainTitle>

          <Styled.MainText>
            Clique no botão abaixo para entrar no sistema.
          </Styled.MainText>

          <Button
            size="small"
            track={buttonClickTracking}
            trackName="navigate_to_login"
            onClick={() => navigate(urls.login)}
            className={cn(isDesktop ? 'w-[undefined]' : 'w-full', 'mt-14')}
          >
            Entrar na minha conta
          </Button>
        </Styled.Container>
      )
    } else if (isResetTokenValid === false) {
      return (
        <Styled.Container>
          <Styled.Image src={emailFail} />

          <Styled.MainTitle>E-mail expirado</Styled.MainTitle>

          <Styled.MainText margin="0 0 2.4rem">
            O link de redefinição de senha que foi enviado para o seu e-mail
            expirou.
          </Styled.MainText>

          <Styled.SubText>
            Clique em reenviar e-mail aqui embaixo para receber um novo.
          </Styled.SubText>

          <Button
            onClick={handleResendEmail}
            track={buttonClickTracking}
            trackName="resend_password_reset_email"
            className="mb-8 w-full"
          >
            Reenviar e-mail
          </Button>

          <LinkButton
            size="small"
            to={urls.login}
            track={buttonClickTracking}
            trackName="navigate_to_login"
            variation="borderless"
          >
            Entrar na minha conta
          </LinkButton>
        </Styled.Container>
      )
    } else {
      return (
        <FormProvider {...formMethods}>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Styled.MainTitle>Criar nova Senha</Styled.MainTitle>

            <Styled.MainText margin="0 0 4rem">
              Agora é a hora de criar sua nova senha, pense em algo fácil para
              lembrar no futuro.
            </Styled.MainText>

            <Styled.InputButtonContainer>
              <InputText
                name="password"
                label="Crie sua Senha de Acesso"
                placeholder="Crie algo fácil de lembrar"
                type="password"
                scale="small"
                className="mb-4 w-full"
              />

              <PasswordValidator
                value={watch('password')}
                callback={setPasswordValid}
              />

              <FormButton
                disabled={submitDisabled}
                track={buttonClickTracking}
                trackName="save_new_password"
                className={cn(isDesktop ? 'mt-14' : 'mt-32', 'w-full')}
              >
                Salvar
              </FormButton>
            </Styled.InputButtonContainer>
          </Styled.Form>
        </FormProvider>
      )
    }
  }

  if (loading) return <Loading active />

  return handleDisplayContent()
}

export default ResetPassword
