// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'
import { timeObjectList } from 'utils/constants'

// Libraries
import { useFormContext } from 'react-hook-form'

// Components
import * as Styled from './styled'

const OPTIONS = timeObjectList.map((hour) => ({
  label: hour,
  value: hour,
}))

type Props = {
  disabled?: boolean
  displayError?: boolean
  displayLine?: boolean
  labelStart?: string
  labelEnd?: string
  nameStart: string
  nameEnd: string
  placeholderStart?: string
  placeholderEnd?: string
  width?: string
}

const InputTimeRange: FC<Props> = ({
  disabled,
  displayLine = true,
  placeholderStart,
  placeholderEnd,
  labelStart,
  labelEnd,
  nameStart,
  nameEnd,
  displayError = false,
  width,
}) => {
  const { watch } = useFormContext()
  const startTime = watch(nameStart)

  return (
    <Styled.InputWrapper spaced={!displayLine}>
      <Styled.RangeHours
        label={labelStart}
        displayError={displayError}
        disabled={disabled}
        name={nameStart}
        options={OPTIONS}
        placeholder={placeholderStart}
        className={cn('w-[220px]', width)}
      />
      {displayLine && <Styled.Line />}
      <Styled.RangeHours
        label={labelEnd}
        displayError={displayError}
        disabled={disabled}
        name={nameEnd}
        options={OPTIONS.filter((OPTION) => OPTION.value > startTime)}
        placeholder={placeholderEnd}
        className={cn('w-[220px]', width)}
      />
    </Styled.InputWrapper>
  )
}

export default InputTimeRange
