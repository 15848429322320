// Libs
import { cva } from 'class-variance-authority'

export const hintStyles = cva(['mt-[6px]'], {
  variants: {
    size: {
      small: 'text-copy4 h-[12px]',
      medium: 'text-copy3 h-[14px]',
      large: 'text-copy2 h-[16px]',
    },
    color: {
      critical: 'text-text-critical',
      default: 'text-border-input',
      focused: 'text-text',
      success: 'text-text-success',
    },
  },
  defaultVariants: {
    color: 'default',
    size: 'medium',
  },
})
