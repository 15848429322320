// React
import { useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import { IconsPath, IconsType } from './values'

type Props = {
  className?: string
  color?: string
  iconName?: IconsType
  size?: number
  testid?: string
}

const Icon = ({
  className,
  color,
  iconName = 'heart',
  size = 20,
  testid,
}: Props) => {
  const viewBox = IconsPath[iconName][0] || '24 24'
  const theme = useContext(ThemeContext)

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${viewBox}`}
      fill="none"
      data-testid={testid}
    >
      {IconsPath[iconName].map((svg, index) => {
        if (index === 0) return

        return (
          <path
            className={className}
            d={svg}
            fill={color ? color : theme.colors.icon.default}
            key={index}
          />
        )
      })}
    </svg>
  )
}

export default Icon
