// React
import { forwardRef } from 'react'

// Misc
import { cn } from '../../../../utils/classes'
import { inputStyles } from './styles'

type InputAttrs = React.InputHTMLAttributes<HTMLInputElement>

type Props = InputAttrs & {
  'data-testid'?: string
  scale?: 'small' | 'medium' | 'large'
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ scale = 'medium', className, ...attributes }, ref) => (
    <input
      ref={ref}
      {...attributes}
      className={cn(inputStyles({ scale }), className)}
    />
  ),
)
Input.displayName = 'Input'

export default Input
