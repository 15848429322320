// Models
import { IAnamneseTemplateState } from 'storage/assessmentAnamneseTemplate/models'
import { IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// React
import { FC, useLayoutEffect, useMemo } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteModalinviteSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'
import { success, triggerUserInvite } from 'storage/auth/duck'
import {
  triggerLoadAnamneseTemplates,
  triggerLoadMoreAnamneseTemplates,
} from 'storage/assessmentAnamneseTemplate/duck'
import { useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Autocomplete, FormButton, InputText } from 'heeds-ds'
import Modal from '../Modal'

// Constants
const page_size = 20

interface IFormFields {
  name?: string
  email: string
  anamnesis_template_id?: string
}

const ModalInviteAthlete: FC = () => {
  const { invite_url } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { anamneseTemplates, next } = useSelector<
    IStore,
    IAnamneseTemplateState
  >(({ assessmentAnamneseTemplate }) => assessmentAnamneseTemplate)
  const { closeModal } = useModal()
  const dispatch = useDispatch()

  const methods = useForm<IFormFields>({
    delayError: 800,
    mode: 'onChange',
    resolver: yupResolver(athleteModalinviteSchema),
    reValidateMode: 'onChange',
  })
  const { handleSubmit } = methods

  const anamneseTemplateOptions = useMemo(() => {
    return anamneseTemplates?.map((template) => ({
      label: template.title,
      value: template.id,
    }))
  }, [anamneseTemplates])

  const copyText = () => navigator.clipboard.writeText(invite_url || '')

  const getLink = () => {
    if (!invite_url) dispatch(triggerUserInvite({}))
  }

  const onSubmit = (form: IFormFields) => {
    dispatch(triggerUserInvite(form))
    closeModal()
  }

  const loadMoreAnamneses = () => {
    if (next)
      dispatch(triggerLoadMoreAnamneseTemplates({ page: next, page_size }))
  }

  useLayoutEffect(() => {
    dispatch(success({ invite_url: undefined }))
    dispatch(triggerLoadAnamneseTemplates({ page_size }))
  }, [dispatch])

  return (
    <Modal>
      <Styled.Container onSubmit={handleSubmit(onSubmit)}>
        <Styled.Header>
          <Styled.Title>Convidar aluno</Styled.Title>
        </Styled.Header>

        <Styled.Content>
          <FormProvider {...methods}>
            <Styled.Description>
              Seu aluno receberá um e-mail com o link para preenchimento do
              cadastro no sistema.
            </Styled.Description>

            <Styled.InputNameContainer>
              <Styled.ItalicText>(opcional)</Styled.ItalicText>
              <Styled.InputName />
            </Styled.InputNameContainer>

            <InputText
              label="E-mail"
              mandatory
              name="email"
              placeholder="Digite o e-mail do seu aluno"
              scale="small"
              type="email"
              className="mb-[14px] mt-[6px]"
            />

            <Autocomplete
              label="Escolha a anamnese"
              name="anamnesis_template_id"
              onMenuScrollToBottom={loadMoreAnamneses}
              options={anamneseTemplateOptions}
              placeholder="Não enviar"
              scale="small"
            />

            <FormButton
              track={buttonClickTracking}
              trackName="send_invite"
              className="mt-3 w-full"
            >
              Enviar convite
            </FormButton>

            <Styled.Break />

            {invite_url ? (
              <Styled.LinkRow>
                <Styled.LinkContainer>
                  <Styled.LinkText>{invite_url}</Styled.LinkText>
                </Styled.LinkContainer>

                <Styled.CopyButton
                  onClick={copyText}
                  track={buttonClickTracking}
                  trackName="copy_link_to_clipboard"
                />
              </Styled.LinkRow>
            ) : (
              <Styled.InviteButton
                onClick={getLink}
                track={buttonClickTracking}
                trackName="generate_invite_link"
              >
                Gerar link
              </Styled.InviteButton>
            )}

            <Styled.Footer>
              Convite por link direto expira automaticamente em 7 dias.
            </Styled.Footer>
          </FormProvider>
        </Styled.Content>
      </Styled.Container>
    </Modal>
  )
}

export default ModalInviteAthlete
