// Misc
import { cn } from '../../utils/classes'

// Components
import Icon from '../UI/Icons'

type Props = {
  active?: boolean
  checkIcon?: boolean
  closeIcon?: boolean
  label: string
  onClick?: () => void
  scale?: 'large' | 'small'
}

const Chip = ({
  active,
  checkIcon,
  closeIcon,
  label,
  onClick,
  scale,
}: Props) => {
  return (
    <button
      className={cn(
        'flex cursor-pointer items-center justify-center gap-4 whitespace-nowrap rounded-[16px]',
        'h-[34px] border border-badge-purpleText bg-surface px-4 py-2',
        'focus:shadow-lg focus:outline focus:outline-1 focus:outline-badge-purpleText',
        'hover:bg-action-highlightSofblue',
        { 'bg-badge-purpleBackground': active },
        { 'h-[42px]': scale === 'large' },
      )}
      data-testid="chip-container"
      onClick={onClick}
      type="button"
    >
      {checkIcon && (
        <Icon testid="chip-check-icon" iconName="check" size={16} />
      )}

      <p
        className={cn('text-copy5 font-bold text-badge-purpleText', {
          'text-copy4': scale === 'large',
        })}
        data-testid={`chip-label-${label}`}
      >
        {label}
      </p>

      {closeIcon && (
        <Icon testid="chip-close-icon" iconName="close" size={16} />
      )}
    </button>
  )
}

export default Chip
