// Misc
import { cn } from '../../../utils/classes'
import { hintStyles } from './styles'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  color?: 'default' | 'critical' | 'focused' | 'success'
  disabled?: boolean
  displayError?: boolean
  error?: string
  label?: string
  mandatory?: boolean
  name?: string
  size?: 'small' | 'medium' | 'large'
  type?: string
}

const InputWrapper = ({
  children,
  color,
  disabled,
  displayError = true,
  error,
  label,
  name,
  mandatory,
  size = 'medium',
  type,
  className,
}: Props) => {
  return (
    <div
      className={cn('relative flex w-full flex-col justify-between', className)}
    >
      {label && (
        <label
          data-testid="label"
          htmlFor={name}
          className={cn('mb-2 flex text-copy3 font-bold', {
            'text-text-disabled': disabled,
            'text-copy4': size === 'small',
            'text-copy2': size === 'large',
          })}
        >
          {label}
          {mandatory && (
            <p
              data-testid="mandatory-sign"
              className="ml-1 text-copy4 text-text-critical"
            >
              (obrigatório)
            </p>
          )}
        </label>
      )}
      {children}
      {type !== 'password' && displayError && (
        <p data-testid="error-text" className={cn(hintStyles({ color, size }))}>
          {error}
        </p>
      )}
    </div>
  )
}

export default InputWrapper
