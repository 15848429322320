// Models
import { IAthleteItem, IGetAthletesPayload } from 'services/athletes/@types'
import { IAthletesState } from 'storage/athletes/models'
import { FormProvider, useForm } from 'react-hook-form'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { routineModalShareTemplateSchema } from 'schemas'
import { useDebounceFunction, useFilters, useMediaQuery, useModal } from 'hooks'
import { EPlanModelsPortuguese } from 'blocks/dashboard/athlete/AthleteFinancial/Plans'
import {
  triggerLoadAthletes,
  triggerLoadMoreAthletes,
} from 'storage/athletes/duck'

// Components
import {
  Checkbox,
  CommonList,
  InputSearch,
  LoadingSpinner,
  Tag,
} from 'heeds-ds'
import { Modal } from 'components'

const page_size = 20

interface ISelectedAthlete {
  label: number
  active: boolean
}

export interface IModalNewRoutineInputs {
  selectAll: boolean
  selectedAthlete?: ISelectedAthlete[]
}

type Props = {
  onSend: (id: number[]) => void
}

const ModalShareRoutine: FC<Props> = ({ onSend }) => {
  const { closeModal } = useModal()
  const { colors, breakpoints } = useContext(ThemeContext)
  const { athletes, loading, error } = useSelector<IStore, IAthletesState>(
    (state) => state.athletes,
  )
  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const { filters, setFilter } = useFilters<IGetAthletesPayload>(
    window.location.search,
  )

  const formMethods = useForm<IModalNewRoutineInputs>({
    resolver: yupResolver(routineModalShareTemplateSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    delayError: 800,
  })
  const { handleSubmit, setValue, watch } = formMethods

  const selectAll = watch('selectAll')

  const onSubmit = (formData: IModalNewRoutineInputs) => {
    const selectedAthletes =
      formData.selectedAthlete?.reduce((acc: number[], value) => {
        if (value.active) return [...acc, value.label]

        return acc
      }, []) || []
    onSend(selectedAthletes)
    closeModal()
  }

  const renderItems = (item: IAthleteItem) => {
    const prefix = `selectedAthlete.${[item.id]}.active`
    return (
      <Tag
        active={Boolean(watch(`selectedAthlete`)?.[item.id]?.active)}
        key={item.id}
        margin={isTablet ? '0 0 0.8rem' : '0'}
      >
        <div className="flex items-center justify-center gap-2 truncate">
          <Checkbox id={prefix} name={prefix} />
          <p className="truncate text-copy4 font-extrabold text-text-secondary">
            {item.name}
          </p>
        </div>

        <p className="text-copy4">
          {item.plan_periodicity &&
            EPlanModelsPortuguese[item.plan_periodicity]}
        </p>
      </Tag>
    )
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleReloadAthletes = (newFilters: IGetAthletesPayload) => {
    const query = {
      ...newFilters,
      page_size,
    }
    dispatch(triggerLoadAthletes(query))
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IGetAthletesPayload) => void
  >(handleReloadAthletes, 1000)

  const handleEndReached = () => {
    console.log('aqui')
    if (athletes && !loading && athletes?.next) {
      const query = {
        ...filters,
        page: athletes.next,
        page_size,
      }
      dispatch(triggerLoadMoreAthletes(query))
    }
  }

  useEffect(() => {
    const allSelected = athletes?.results.reduce(
      (accumulator: ISelectedAthlete[], athlete) => {
        accumulator[athlete.id] = {
          label: athlete.id,
          active: selectAll,
        }

        return accumulator
      },
      [],
    )

    setValue('selectedAthlete', allSelected)
  }, [athletes, selectAll, setValue])

  useLayoutEffect(() => {
    if (!athletes?.results && !loading && !error) {
      const query = {
        ...filters,
        page_size,
      }
      dispatch(triggerLoadAthletes(query))
    }
  }, [athletes?.results, dispatch, error, filters, loading])

  return (
    <Modal
      onClose={closeModal}
      title="Lista de alunos"
      description="Selecione para quais alunos você quer enviar esse modelo de rotina."
      primaryButton={{ name: 'Enviar', onClick: handleSubmit(onSubmit) }}
      secondaryButton={{ name: 'Cancelar' }}
    >
      <FormProvider {...formMethods}>
        <div className="px-6">
          <InputSearch
            onChange={onSearchChange}
            value={filters.name || ''}
            className="mb-6 mt-8"
          />

          <div className="mb-8 flex justify-end">
            <Checkbox
              id="selectAll"
              label="Selecionar todos"
              name="selectAll"
            />
          </div>

          <div className="mb-4 h-[220px] w-full overflow-auto">
            <CommonList<IAthleteItem>
              columns={[]}
              data={athletes?.results || []}
              hasMore={!!athletes?.next}
              onEndReached={handleEndReached}
              renderItem={renderItems}
              showHeader={false}
              refreshing={loading}
              loaderComponent={
                <LoadingSpinner color={colors.icon.hovered} size={48} />
              }
            />
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}

export default ModalShareRoutine
