// Libraries
import { ToggleGroup } from 'heeds-ds'
import styled from 'styled-components'

type Props = {
  active?: boolean
  select?: boolean
  padding?: string
}

export const Align = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ active }) => (active ? '2.4rem' : '3.2rem')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: ${({ active }) => (active ? 'row' : 'column')};
    gap: ${({ active }) => (active ? '0.8rem' : '1.6rem')};
    justify-content: space-between;
  }
`

export const AlignMobile = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
`

export const ContainerInputTime = styled.div<Props>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.subdued};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  margin-bottom: 4.8rem;
  min-height: 8.7rem;
  padding: ${({ active }) => (active ? '3.2rem 1.6rem' : '3.2rem 2.4rem')};
  position: relative;
  width: 100%;

  ::before {
    content: '';
    background-color: ${({ theme }) => theme.colors.icon.disabled};
    border-radius: 3px 0 0 3px;
    height: 100%;
    left: 0;
    position: absolute;
    width: 4px;
    visibility: ${({ active }) => (active ? 'hidden' : 'visible')};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.surface.subdued : 'transparent'};
    height: 7.2rem;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${({ active }) => (active ? '0rem' : '1.1rem')};
    padding: 1.6rem 2.4rem 1.6rem 1.6rem;
  }
`

export const Content = styled.div<Props>`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: ${({ padding }) => padding};
  }
`

export const DayToggleGroup = styled(ToggleGroup).attrs((props) => ({
  color: props.theme.colors.icon.onPrimary,
}))``

export const FormContainer = styled.form``

export const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`
