// Models
import { EDaysName } from 'utils/functions'
import { IAppointmentTime, IProfileState } from 'storage/profile/models'
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import { IAthleteScheduleState } from 'storage/athleteSchedule/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useMemo, useState } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteScheduleSchema } from 'schemas'
import { handleExtraInformationToAthlete } from 'filters/athlete'
import {
  findScheduleConflicts,
  findRecurringScheduleConflicts,
  formatSubmitPayload,
  resetLoadedSchedules,
} from './filters'
import {
  triggerPostAthleteSchedules,
  triggerGetAthleteSchedules,
} from 'storage/athleteSchedule/duck'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Body } from 'components/UI/Typography'
import { Aligner, InputText, Loading, Switch } from 'heeds-ds'
import {
  FooterActionButton,
  InputTimeRange,
  NewContentBox,
  Schedule,
  ScheduleDayTag,
} from 'components'
import { ModalTimeSchedule } from 'components/modals'

// Constants
import { WEEKDAYS } from 'utils/calendarUtils'

const LONG_WEEK_DAYS = [
  { label: 'SEGUNDA', value: 'mon' },
  { label: 'TERÇA', value: 'tue' },
  { label: 'QUARTA', value: 'wed' },
  { label: 'QUINTA', value: 'thu' },
  { label: 'SEXTA', value: 'fri' },
  { label: 'SÁBADO', value: 'sat' },
  { label: 'DOMINGO', value: 'sun' },
]

// TODO: essa interace IAppointmentTime nao é a melhor pra esse caso
export interface IFormFields {
  allDays?: Pick<IAppointmentTime, 'days' | 'start' | 'end'>
  sun?: IAppointmentTime
  mon?: IAppointmentTime
  tue?: IAppointmentTime
  wed?: IAppointmentTime
  thu?: IAppointmentTime
  fri?: IAppointmentTime
  sat?: IAppointmentTime
  location?: string
  isRecurrent?: boolean
}

const AthleteSchedule: FC = () => {
  const { loading, otherAthletesSchedules } = useSelector<
    IStore,
    IAthleteScheduleState
  >((state) => state.athleteSchedule)
  const { athletesSchedules } = useSelector<IStore, IProfileState>(
    (state) => state.profile,
  )
  const { id: athleteId } = useOutletContext<IAthleteOutletContext>()
  const { openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  // TODO: Remover essa parte apos refatoracao da parte do modal E do schedule!
  const [initialDate, setInitialDate] = useState('')
  const [modalDisplayMode, setModalDisplayMode] = useState({
    variation: 'conflict',
    day: '',
  })

  const formMethods = useForm<IFormFields>({
    resolver: yupResolver(athleteScheduleSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const { handleSubmit, reset, watch } = formMethods

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { allDays, isRecurrent, location, ...weekdays } = watch()

  const conflicts = useMemo(() => {
    if (!otherAthletesSchedules) return

    if (
      isRecurrent &&
      allDays?.start &&
      allDays?.end &&
      allDays?.days?.length
    ) {
      return findRecurringScheduleConflicts(
        otherAthletesSchedules,
        allDays as Pick<IAppointmentTime, 'days' | 'start' | 'end'>,
      )
    }
    if (!isRecurrent) {
      return findScheduleConflicts(otherAthletesSchedules, weekdays)
    }
  }, [allDays, isRecurrent, otherAthletesSchedules, weekdays])

  const athleteWithExtraInfo = handleExtraInformationToAthlete(
    athletesSchedules?.results ?? [],
    athleteId,
  )

  const openNewTimeModal = (editMode: string, selectedDay?: string) => {
    editMode &&
      setModalDisplayMode((prevState) => ({
        ...prevState,
        variation: editMode,
      }))
    setModalDisplayMode((prevState) => ({
      ...prevState,
      day: selectedDay ?? '',
    }))
    openModal('time-conflict-modal')
  }

  const onSubmit = (data: IFormFields) => {
    formatSubmitPayload(data, (payload) => {
      dispatch(
        triggerPostAthleteSchedules({
          athlete: Number(athleteId),
          ...payload,
        }),
      )
    })
  }

  useLayoutEffect(() => {
    if (athleteId) {
      dispatch(
        triggerGetAthleteSchedules({
          athlete: Number(athleteId),
          callback: (response) => resetLoadedSchedules(reset, response),
        }),
      )
    }
  }, [athleteId, dispatch, reset])

  if (loading) {
    return <Loading active />
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.FormContainer
        id="athlete-schedule"
        onSubmit={handleSubmit(onSubmit)}
      >
        <NewContentBox
          title="Horários"
          description={
            <Aligner flex="column">
              <Body
                color={theme.colors.text.subdued}
                margin="0.8rem 0 0"
                type="copy3"
              >
                Nesse passo, você vai adicionar o local do treino do aluno e
                escolher os dias que ele vai treinar com você.
              </Body>
              <Schedule
                margin="3.2rem 0 0"
                loading={loading}
                startDate={initialDate}
                savedAthletes={athleteWithExtraInfo}
                callback={(date) => setInitialDate(date)}
                onlyButton
              />
            </Aligner>
          }
        >
          <Styled.FormInner>
            <Styled.Content active={isRecurrent}>
              <InputText
                label="Local do treino"
                name="location"
                placeholder="Digite aqui o local do treino"
              />
              <Styled.ContainerInputTime active={isRecurrent}>
                <Styled.AlignMobile>
                  <Switch name="isRecurrent" />
                  <Body type={isDesktop ? 'copy3' : 'copy2'} whiteSpace>
                    Mesmo horário todos os dias
                  </Body>
                </Styled.AlignMobile>
                {isRecurrent && (
                  <InputTimeRange
                    nameStart="allDays.start"
                    nameEnd="allDays.end"
                    placeholderStart={
                      isDesktop ? 'Horário de início' : 'Início'
                    }
                    placeholderEnd={
                      isDesktop ? 'Horário de término' : 'Término'
                    }
                  />
                )}
              </Styled.ContainerInputTime>
              {isRecurrent && (
                <Styled.DayToggleGroup
                  alignment={isDesktop ? 'row' : 'column'}
                  gap={isDesktop ? '1.15rem' : '2.4rem'}
                  name="allDays.days"
                  optionSize={isDesktop ? 'w-[88px]' : 'w-full'}
                  options={LONG_WEEK_DAYS}
                  type="multiple"
                  conflicts={conflicts?.map((obj) => obj.day)}
                  conflictMessage="Conflito de horário"
                  onConflict={() => openNewTimeModal('conflict')}
                  className="mb-5"
                />
              )}
              <Styled.Align active={isRecurrent}>
                {WEEKDAYS.map(
                  (day) =>
                    !isRecurrent && (
                      <ScheduleDayTag
                        key={day}
                        label={EDaysName[day]}
                        name={day}
                        width={isDesktop ? '75%' : '100%'}
                        conflict={
                          !!conflicts?.find(
                            (conflictDay) => conflictDay.day === day,
                          )
                        }
                        onConflict={() =>
                          openNewTimeModal('singleDayConflict', day)
                        }
                      />
                    ),
                )}
              </Styled.Align>
            </Styled.Content>
          </Styled.FormInner>
        </NewContentBox>
        <FooterActionButton loading={loading} primaryTitleButton="Salvar" />
      </Styled.FormContainer>
      {isVisible === 'time-conflict-modal' && (
        <ModalTimeSchedule
          editId={Number(athleteId)}
          athleteListSaved={athleteWithExtraInfo}
          variation={modalDisplayMode.variation}
          selectedDay={modalDisplayMode.day}
        />
      )}
    </FormProvider>
  )
}

export default AthleteSchedule
