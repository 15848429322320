// Models
import { IAuthState } from 'storage/auth/models'
import { IFinancialExtraPlan } from 'services/financial/@types'
import IStore from 'lib/redux/models'

// React
import { FC, useContext } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { prepareExtraPlansToPayload } from 'filters/profile'
import { profileExtraPlanSchema } from 'schemas'
import {
  triggerCreateProfileExtraPlan,
  triggerUpdateProfileExtraPlan,
} from 'storage/financial/duck'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Autocomplete,
  FormButton,
  InputText,
  ToggleGroup,
} from 'heeds-ds'

// Assets
import { timeObjectList } from 'utils/constants'

// Constants
const SHORT_WEEK_DAYS = [
  { label: 'S', value: 'mon' },
  { label: 'T', value: 'tue' },
  { label: 'Q', value: 'wed' },
  { label: 'Q', value: 'thu' },
  { label: 'S', value: 'fri' },
  { label: 'S', value: 'sat' },
  { label: 'D', value: 'sun' },
]

const LONG_WEEK_DAYS = [
  { label: 'SEGUNDA', value: 'mon' },
  { label: 'TERÇA', value: 'tue' },
  { label: 'QUARTA', value: 'wed' },
  { label: 'QUINTA', value: 'thu' },
  { label: 'SEXTA', value: 'fri' },
  { label: 'SÁBADO', value: 'sat' },
  { label: 'DOMINGO', value: 'sun' },
]

type Props = {
  borderColor?: 'critical' | 'default'
  extraPlan?: IFinancialExtraPlan<string>
  list: IFinancialExtraPlan<string>[]
  onCancel: () => void
}

const PlanExtraWorkoutsForm: FC<Props> = ({
  borderColor = 'default',
  extraPlan,
  onCancel,
  list,
}) => {
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const formMethods = useForm<IFinancialExtraPlan<string>>({
    resolver: yupResolver(profileExtraPlanSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
    defaultValues: {
      ...(extraPlan || {}),
    },
  })
  const { handleSubmit, setError, reset } = formMethods

  const onSubmit = (data: IFinancialExtraPlan<string>) => {
    if (list.find((item) => item.name === data.name)) {
      setError('name', { message: 'Você já tem um plano com esse nome.' })
      return
    }

    const payload = prepareExtraPlansToPayload([data])[0]

    if (extraPlan) {
      dispatch(triggerUpdateProfileExtraPlan(payload))
    } else {
      userData?.profileId &&
        dispatch(
          triggerCreateProfileExtraPlan({
            profile_pk: userData.profileId,
            ...payload,
          }),
        )
    }

    reset()
    onCancel()
  }

  return (
    <Styled.FormContainer
      borderColor={borderColor}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Styled.Title>
        {extraPlan ? 'Edite o seu treino' : 'Crie aqui o seu treino'}
      </Styled.Title>

      <FormProvider {...formMethods}>
        <InputText
          label="Digite o nome do treino"
          name="name"
          placeholder="Circuito funcional, treino na praia..."
          scale="small"
          className="mb-3"
        />

        <InputText
          label="Local do treino"
          name="location"
          placeholder="Você pode adicionar uma academia, um local público..."
          scale="small"
          className="mb-3"
        />

        <Aligner gap="24px">
          <Autocomplete
            displayError
            label="Horário de Início"
            name="start_time"
            options={timeObjectList.map((time) => {
              return {
                label: time,
                value: time,
              }
            })}
            placeholder="Ex.: 9h"
            scale="small"
            className="mb-3"
          />

          <Autocomplete
            displayError
            label="Horário de Término"
            name="end_time"
            options={timeObjectList.map((time) => {
              return {
                label: time,
                value: time,
              }
            })}
            placeholder="Ex.: 10h"
            scale="small"
            className="mb-3"
          />
        </Aligner>

        <InputText
          label="Valor do treino extra"
          mask="currency"
          name="price"
          placeholder="R$ 0,00"
          scale="small"
          className="mb-3"
        />

        <ToggleGroup
          alignment={isDesktop ? 'row' : 'column'}
          gap={isDesktop ? '1.6rem' : '2.4rem'}
          label="Selecione o dia do treino:"
          name="days"
          optionSize="100%"
          options={isDesktop ? SHORT_WEEK_DAYS : LONG_WEEK_DAYS}
          radius={isDesktop ? '7px' : '15px'}
          type="multiple"
          className="mb-5"
        />

        <Aligner justify="space-between">
          <Styled.CancelButton
            onClick={onCancel}
            track={buttonClickTracking}
            trackName="close_extra_plan_form"
          >
            Cancelar
          </Styled.CancelButton>

          <FormButton
            size="small"
            track={buttonClickTracking}
            trackName={`${extraPlan ? 'update' : 'add'}_extra_plan`}
          >
            {extraPlan
              ? 'Salvar edição'
              : `+ Adicionar ${isDesktop ? 'treino' : ''}`}
          </FormButton>
        </Aligner>
      </FormProvider>
    </Styled.FormContainer>
  )
}

export default PlanExtraWorkoutsForm
