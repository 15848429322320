// React
import { forwardRef } from 'react'

// Misc
import { cn } from '../../../../utils/classes'

type RadioAttrs = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'placeholder'
>

type Props = RadioAttrs & {
  'data-testid'?: string
  label?: string
  scale?: 'default' | 'large'
}

const Radio = forwardRef<HTMLInputElement, Props>(
  ({ label, scale = 'default', className, ...attributes }, ref) => (
    <label
      aria-disabled={attributes.disabled}
      className={cn(
        'group inline-flex cursor-pointer items-center gap-2 text-copy4 font-normal',
        'aria-disabled:pointer-events-none aria-disabled:text-text-disabled',
        className,
      )}
    >
      <input
        type="radio"
        ref={ref}
        {...attributes}
        className={cn(
          'm-0 grid w-4 cursor-pointer appearance-none place-content-center rounded-[50%] border border-icon',
          'h-4 bg-white text-icon transition-all duration-300 ease-in-out',
          'focus:outline-1 focus:outline-offset-1 focus:outline-icon-disabled',
          'disabled:border-icon-disabled disabled:text-icon-disabled',
          'before:transition-all before:duration-300 before:ease-in-out',
          'checked:before:scale-100 disabled:before:bg-icon-disabled',
          'before:h-2 before:content-[""]',
          'before:w-2 before:bg-icon',
          'before:scale-0 before:rounded-[50%]',
          'aria-disabled:group-hover:border-icon-disabled aria-disabled:group-hover:before:bg-icon-disabled',
          'group-hover:border-icon-hovered group-hover:before:bg-icon-hovered',
          {
            'w-6 h-6 before:h-3 before:w-3': scale === 'large',
          },
        )}
      />
      {label}
    </label>
  ),
)
Radio.displayName = 'Radio'

export default Radio
