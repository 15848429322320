// Libs
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

// React
import { useCallback, useMemo } from 'react'

// Misc
import { objectToQueryString, parseQueryParams } from 'lib/query-string'

export type Filters = Record<string, string[]>

const useFilters = <T extends object>(initialParams?: URLSearchParamsInit) => {
  const [searchParams, setSearchParams] = useSearchParams(initialParams)

  const filters = useMemo(
    () => parseQueryParams(String(searchParams)),
    [searchParams],
  )

  const hasFilters = useMemo(() => Object.keys(filters).length > 0, [filters])

  const isValueIncluded = useCallback(
    (name: string, value: string) => {
      const encodedValue = encodeURIComponent(value)
      return searchParams
        .toString()
        .split('&')
        .some((param) => {
          const [key, val] = param.split('=')
          return key === name && val === encodedValue
        })
    },
    [searchParams],
  )

  const removeFilter = useCallback(
    (name: string, value?: string) => {
      const paramsObject = parseQueryParams(searchParams.toString())
      const filterValue = parseQueryParams(searchParams.toString())[name]

      const filtersObject = {
        ...paramsObject,
        [name]: value ? filterValue.filter((item) => `${item}` !== value) : [],
      }

      setSearchParams(filtersObject, { replace: true })

      return filtersObject
    },
    [searchParams, setSearchParams],
  )

  const resetFilters = useCallback(
    (filters: T) => {
      const filtersString = objectToQueryString(filters, 'none')

      setSearchParams(filtersString, { replace: true })
    },
    [setSearchParams],
  )

  const setFilter = useCallback(
    (name: string, value: string, uniqueValue?: boolean) => {
      if (!value && uniqueValue) return removeFilter(name)

      const paramsObject = parseQueryParams(searchParams.toString())
      const filterValue = parseQueryParams(searchParams.toString())[name]

      const filtersObject = {
        ...paramsObject,
        [name]: filterValue && !uniqueValue ? [...filterValue, value] : [value],
      }

      setSearchParams(filtersObject, { replace: true })

      return filtersObject
    },
    [removeFilter, searchParams, setSearchParams],
  )

  const toggleFilter = useCallback(
    (name: string, value: string, uniqueValue?: boolean) => {
      const isIncluded = isValueIncluded(name, value)

      if (isIncluded) {
        return removeFilter(name, value)
      }

      return setFilter(name, value, uniqueValue)
    },
    [isValueIncluded, removeFilter, setFilter],
  )

  return {
    filters: filters as T,
    hasFilters,
    isValueIncluded,
    removeFilter,
    resetFilters,
    setFilter,
    toggleFilter,
  }
}

export default useFilters
