// Models
import { IProfileState } from 'storage/profile/models'
import { TypeGenders } from 'models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Misc
import { getCepByViaCep } from 'services/external'
import { getPersonalProfile, updatePersonalProfile } from 'storage/profile/duck'
import {
  prepareGeneralProfileToDisplay,
  prepareGeneralProfileToPayload,
} from 'filters/profile'
import { profileInformationSchema } from 'schemas'
import { stateObjectList } from 'utils/constants'
import useGlobalForms from 'hooks/useGlobalForms'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import {
  DatePicker,
  InputPicker,
  InputText,
  Loading,
  RadioGroup,
} from 'heeds-ds'
import { FooterActionButton, NewContentBox } from 'components'
import { ThemeContext } from 'styled-components'

// Constants
import { GENDER_OPTIONS } from 'utils/constants'

export interface IFormInputs {
  name?: string
  email?: string
  phone_number?: string
  birthdate?: string
  gender?: TypeGenders
  cref?: string
  cref_UF?: string
  cep?: string
  city?: string
  address?: string
  complement?: string
  instagram?: string
  state?: string
}

const ProfileGeneral: FC = () => {
  const { profile, loading } = useSelector<IStore, IProfileState>(
    (state) => state.profile,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [cepIsLoading, setCepIsLoading] = useState(false)

  const formMethods = useGlobalForms<IFormInputs>({
    schema: profileInformationSchema,
  })
  const {
    formState: { isValid },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = formMethods

  const watchingCep = watch('cep')

  const onSubmit = (data: IFormInputs) => {
    const dataPayload = prepareGeneralProfileToPayload(data)
    dispatch(updatePersonalProfile({ ...dataPayload }))
  }

  const updateLinkedFields = useCallback(
    (address: string, city: string, state: string) => {
      setValue('address', address)
      setValue('city', city)
      setValue('state', state)
    },
    [setValue],
  )

  const getCep = useCallback(async () => {
    if (watchingCep) {
      setCepIsLoading(true)

      try {
        const { localidade, logradouro, uf } = await getCepByViaCep(
          watchingCep.replace('-', ''),
        )
        updateLinkedFields(logradouro ?? '', localidade ?? '', uf ?? '')
      } catch (error) {
        updateLinkedFields('', '', '')
        setError('cep', { message: 'CEP não encontrado!' })
      } finally {
        setCepIsLoading(false)
      }
    }
  }, [setError, updateLinkedFields, watchingCep])

  useEffect(() => {
    if (watchingCep?.match(/\d{5}-\d{3}/)) getCep()
  }, [getCep, watchingCep])

  useEffect(() => {
    if (profile) {
      const profileToDisplay = prepareGeneralProfileToDisplay(profile)
      reset({ ...profileToDisplay })
    }
  }, [profile, reset])

  useLayoutEffect(() => {
    if (!profile) dispatch(getPersonalProfile())
  }, [dispatch, profile, navigate])

  if (loading) {
    return <Loading active />
  }

  return (
    <FormProvider {...formMethods}>
      <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Styled.Container>
          <NewContentBox
            title="Dados Gerais"
            description={
              <>
                {'Preencha seus dados pessoais.'}
                <br />
                {'Nome e e-mail obrigatórios.'}
              </>
            }
          >
            <Styled.InputsContainer>
              <InputText
                label="Nome Completo"
                mandatory
                name="name"
                placeholder="Digite seu nome completo"
                scale="small"
              />
              <InputText
                disabled
                label="E-mail"
                mandatory
                name="email"
                placeholder="Digite seu e-mail"
                scale="small"
                type="email"
              />
              <InputText
                label="Telefone"
                name="phone_number"
                mask="phone"
                placeholder="(00) 9 0000-0000"
                scale="small"
              />
              <DatePicker
                displayError
                label="Data de Nascimento"
                placeholder="Selecione a data de nascimento"
                scale="small"
                name="birthdate"
              />
              <RadioGroup
                label="Sexo"
                name="gender"
                options={GENDER_OPTIONS}
                className="mb-[30px] mt-0"
              />
            </Styled.InputsContainer>
          </NewContentBox>

          <NewContentBox
            title="Informações Adicionais"
            description={
              isDesktop
                ? 'Esses dados são opcionais.'
                : 'Preencha seus dados pessoais'
            }
          >
            <Styled.InputsContainer>
              <Styled.CREFContainer>
                <InputText label="CREF" mask="cref" name="cref" scale="small" />
                <InputPicker
                  showIcon
                  options={stateObjectList}
                  optionsCallBack={(option) => setValue('cref_UF', option)}
                  name={register('cref_UF').name}
                  onChange={register('cref_UF').onChange}
                  onBlur={register('cref_UF').onBlur}
                  placeholder="UF"
                  refName={register('cref_UF').ref}
                  scale="small"
                  value={watch('cref_UF') || ''}
                />
              </Styled.CREFContainer>
              <InputText
                label="CEP"
                mask="cep"
                name="cep"
                placeholder="Digite primeiro o seu CEP"
                scale="small"
              />
              <InputText
                disabled={cepIsLoading}
                label="Cidade"
                name="city"
                placeholder="Digite o nome da cidade"
                scale="small"
              />
              <InputText
                disabled={cepIsLoading}
                label="Estado"
                name="state"
                placeholder="Digite o nome do estado"
                scale="small"
              />
              <InputText
                disabled={cepIsLoading}
                label="Endereço"
                name="address"
                placeholder="Rua, Av..."
                scale="small"
              />
              <InputText
                label="Complemento"
                name="complement"
                placeholder="Apto, sem complemento..."
                scale="small"
              />
              <InputText
                label="Instagram"
                name="instagram"
                placeholder="Opcional, link do seu instagram aqui"
                scale="small"
              />
            </Styled.InputsContainer>
          </NewContentBox>
        </Styled.Container>
        <FooterActionButton
          disabled={!isValid}
          loading={loading}
          primaryTitleButton="Salvar"
        />
      </Styled.FormContainer>
    </FormProvider>
  )
}

export default ProfileGeneral
